import { makeAutoObservable } from 'mobx'
import QueryString from 'querystring'
import React from 'react'
import {
  notification,
  Popconfirm,
  Select,
  Avatar,
  Button as ButtonLink,
  Spin,
  Tooltip,
  Progress,
  Tag,
} from 'antd'
import PagesError from '../components/PagesError'
import { Row, Button } from 'reactstrap'
import colors from '../../src/configs/colors'
import styles from '../views/ContractStatus/Modals/RequestModal/requestModal.module.scss'
import api from '../utils/axiosAuthInterceptor'
import dayjs from 'dayjs'
import { CheckCircleOutlined } from '@ant-design/icons'

const axios = api
const confirmColor = colors.confirmColor
const rejectColor = colors.rejectColor
const { Option } = Select

class RequestsStore {
  uploads = []
  search = undefined
  userId = undefined
  userTypeId = undefined
  isModalOpen = false
  isModalContractCreateOpen = false
  isModalSendNotificationOpen = false
  isModalSignedInfoOpen = false
  btnCreateNewContract = true
  btnSendInModalSendNotifcation = true
  contractRequestContractId = undefined

  downloadFileLoading = false
  currentFileLoading = null

  errors = []
  isLoaded = false

  page = 1
  total = undefined
  perPage = 10

  showStatistics = false

  categories = []

  taskModel = null

  saveButtonDisabled = true
  saveButtonLoading = false

  isModalRequestOpen = false
  loadingChangeRequestNotify = false

  overdueRequests = 0
  averageDelayTime = 0

  requests = []
  filteredRequests = []

  filterName = ''
  filterType = null
  filterStatus = null
  filterDate = []
  requestTypes = []
  requestType = null
  requestStatuses = []

  sortedRequests = []
  tableHeaders = []
  activeFullRequest = {
    id: null,
    theme: '',
    statusName: '',
    typeName: '',
    comments: [],
    requestContracts: [],
    productGroups: [],
    splitFile: null,
    links: [],
    requestFiles: [],
    requestUserName: '',
    requestUserSurname: '',
    prices: [],
    created_at: '',
    notification: true,

    canApprovePriceBrand: false,
    canApprovePriceRequest: false,
    canLoadRequest: false,
    canLoadPrice: false,
    canAddContracts: false,
    canConfirmContract: false,
    canChangeStage: false,

    stage_prices: null,
    stage_confirmed_po: null,
    stage_partly: null,
    stage_finished: null,
    stageButton: 'All prices are loaded and confirmed',
    availableStageButton: false,
    testFiles: [],
    stage: 1,
  }
  saveActiveFullRequest = {}
  loadingSignContract = false
  isDisabledRequestCommentButton = true
  isDisabledAddRequestFileButton = true
  isDisabledAddPriceFileButton = true
  isDisabledAddTestFileButton = true

  productGroups = []
  productGroupsForAdd = []
  selectedProductGroup = []
  selectedProductGroup_tree = null

  factories = []
  contractFactory = undefined
  contractNumber = ''
  contractPart = ''

  contracts = []
  selectedContract = null
  isDisabledAddContract = true
  loadingTable = false
  users = []
  isDisabledAddUser = true

  signedContractInfo = {
    id: 1,
    requestId: '1',
    contractId: '2',
    comment: 'TEST TEST TEST TEST TEST TEST TEST TEST TEST',
    user: 'vladimir zaitsev',
    date: '2020-12-09 10:00:00',
    files: [
      {
        id: 1,
        original_name: 'file1.docx',
      },
      {
        id: 2,
        original_name: 'file2.docx',
      },
    ],
  }

  models = []

  hasAccessAddRequests = true
  hasAccessAddPrices = true
  hasAccessAddContracts = true
  hasAccessCompleteRequests = true

  isRequestLoading = false
  isPriceLoading = false
  isTestLoading = false
  isContractLoading = false
  requestModerator = false
  accessPO = false

  editName = undefined
  editType = undefined
  editGroups = undefined
  isEditModalOpen = false
  editSaveButtonDisabled = false
  editSaveButtonLoading = false

  filterSettings = { type: 'Menu' }
  fields = { text: 'text', value: 'value' }
  format = { type: 'datetime', format: 'M/d/y hh:mm a' }

  managers = undefined
  brandManagers = undefined
  engineers = undefined
  names = []
  numbers = []
  filtersLoaded = false

  filters = {
    names: [],
    managers: [],
    engineers: [],
    brandManagers: [],
    requestTypes: [],
    requestStatuses: [],
    numbers: [],
    text: undefined,
    filterContracts: [],
    filterManager: null,
    filterModels: [],
    productGroups: [],
    lastEdited: null,
    filterWeekNumber: undefined,
    createdAt: null,
  }

  filteredFilters = {
    numbers: [],
    names: [],
    managers: [],
    engineers: [],
    brandManagers: [],
    requestTypes: [],
    requestStatuses: [],
  }

  filtersParams = [
    {
      opened: false,
      loaded: false,
      checkedAll: false,
      input: '',
    },
    {
      loaded: false,
      opened: false,
      checkedAll: false,
      input: '',
    },
    {
      loaded: false,
      opened: false,
      checkedAll: false,
      input: '',
    },
    {
      loaded: false,
      opened: false,
      checkedAll: false,
      input: '',
    },
    {
      loaded: false,
      opened: false,
      checkedAll: false,
      input: '',
    },
    {
      loaded: false,
      opened: false,
      checkedAll: false,
      input: '',
    },
    {
      loaded: false,
      opened: false,
      checkedAll: false,
      input: '',
    },
    {
      loaded: false,
      opened: false,
      checkedAll: false,
      input: '',
    },
    {
      loaded: false,
      opened: false,
      checkedAll: false,
      input: '',
    },
  ]

  sort = {
    id: true,
    theme: true,
    manager: true,
    engineer: true,
    brand: true,
    typeName: true,
    comment: true,
    created_at: true,
    last_edited_data: true,
    statusName: true,
  }
  currentSort = undefined
  currentSortDirection = undefined
  commentsLang = undefined

  mainColumns = [
    'id',
    'theme',
    'managersString',
    'engineersString',
    'brandsString',
    'typeName',
    'comment',
    'issuePo',
    'statusName',
  ]
  translateComment = []
  requestComment = ''
  splitFile = undefined
  splitUploading = false
  downloadFileProgresBar = []
  downloadFileProgres = []

  loadingTranslate = false
  disabledDownloadPDF = false
  isContractExist = null
  extensionVisible = false
  //стейты для предпросмотра
  filesPreview = null
  indexPreview = null
  arrayLengthPreview = null
  visiblePreview = null
  typePreview = null
  constructor() {
    makeAutoObservable(this)
  }

  saveSearch = (search) => {
    this.search = search
  }

  getUser = async () => {
    let resp = await fetch('/api/node/v1/users/get/current')
    let data = await resp.json()
    this.userId = data.userId
    this.userTypeId = data.userTypeId
  }

  getRequests = () => {
    this.loadingTable = true
    const parsed = QueryString.parse(this.search)

    axios
      .post(`/api/node/v1/statuses/getContractRequestsNew`, {
        page: this.page,
        perPage: this.perPage,
        filters: this.filters,
        currentSort: this.currentSort,
        sortOrder: this.currentSort ? this.currentSortDirection : undefined,
      })
      .then((response) => {
        this.setStateResponseData(response.data)
        this.loadingTable = false
        if (parsed.hasOwnProperty('id')) {
          if (Number.isInteger(+parsed.id)) {
            this.openFullRequest(+parsed.id)
          }
        }
      })
      .catch((err) => console.error(err))
  }

  getRequestsFilters = (type) => {
    axios
      .post(`/api/node/v1/statuses/getRequestFilters`, {
        type: type,
        filters: this.filters,
      })
      .then((response) => {
        let users = [...this.users]

        switch (type) {
          case 'numbers':
          case 'names':
            this.numbers = response.data.numbers
            this.filteredFilters.numbers = response.data.numbers.map(
              (e) => e.value
            )
            this.names = response.data.names
            this.filteredFilters.names = response.data.names.map((e) => e.value)
            this.filtersParams[0].loaded = true
            this.filtersParams[1].loaded = true
            break
          case 'managers':
          case 'engineers':
          case 'brandManagers':
            this.managers = response.data.managers
            this.filteredFilters.managers = response.data.managers.map(
              (e) => e.value
            )
            this.engineers = response.data.engineers
            this.filteredFilters.engineers = response.data.engineers.map(
              (e) => e.value
            )
            this.brandManagers = response.data.brandManagers
            this.filteredFilters.brandManagers =
              response.data.brandManagers.map((e) => e.value)
            this.filtersParams[2].loaded = true
            this.filtersParams[3].loaded = true
            this.filtersParams[4].loaded = true
            break
          case 'requestTypes':
            this.requestTypes = response.data.requestTypes
            this.filteredFilters.requestTypes = response.data.requestTypes.map(
              (e) => e.value
            )
            this.filtersParams[5].loaded = true
            break
          case 'requestStatuses':
            this.requestStatuses = response.data.requestStatuses
            this.filteredFilters.requestStatuses =
              response.data.requestStatuses.map((e) => e.value)
            this.filtersParams[8].loaded = true
            break
          default:
        }
        this.users = users
      })
      .catch((err) => console.error(err))
  }

  updateFilters = (key, value) => {
    this.filters = {
      ...this.filters,
      [key]: value,
    }
    if (key === 'createdAt') {
      this.filters.filterWeekNumber = undefined
    }
    if (key === 'filterWeekNumber') {
      this.filters.createdAt = null
    }
    this.page = 1
    this.saveToStorage()
    this.getRequests()
  }

  updateSort = (key, val) => {
    this[key] = val
  }

  setStateResponseData = (response, update = false) => {
    this.isLoaded = response.isLoaded
    this.requests = response.requests
    this.sortedRequests = response.requests
    this.filteredRequests = response.requests
    this.links = response.links
    this.tableHeaders = response.headers
    this.total = response.total
  }

  saveToStorage = () => {
    let checkedAllArray = this.filtersParams.map((filter) => filter.checkedAll)

    localStorage.setItem(
      'filters',
      JSON.stringify({
        filters: this.filters,
        checkedAll: checkedAllArray,
      })
    )
  }

  getDataFromStorage = async () => {
    let savedFilters = await localStorage.getItem('filters')

    if (savedFilters) {
      let filtersData = await JSON.parse(savedFilters)
      if (filtersData.filters.createdAt !== [] && filtersData.filters.createdAt)
        for (let [index, el] of filtersData.filters.createdAt.entries()) {
          filtersData.filters.createdAt[index] = dayjs(el)
        }

      if (
        filtersData.filters.lastEdited !== [] &&
        filtersData.filters.lastEdited
      )
        for (let [index, el] of filtersData.filters.lastEdited.entries()) {
          filtersData.filters.lastEdited[index] = dayjs(el)
        }

      this.filters = filtersData.filters

      for (let [index, checked] of filtersData.checkedAll.entries()) {
        this.filtersParams[index].checkedAll = checked
      }
    }
    return
  }

  getOtherRequestsFilters = () => {
    this.accessPO = false

    axios
      .post(`/api/node/v1/statuses/getOtherRequestFilters`)
      .then(({ data }) => {
        this.productGroupsForAdd = data.productGroupsForAdd
        this.averageDelayTime = data.averageDelayTime
        this.overdueRequests = data.overdueRequests
        this.productGroups = data.productGroups
        this.contracts = data.contracts
        this.factories = data.factories
        this.models = data.models
        this.requestModerator = data.requestModerator
        this.filtersLoaded = true
        this.users = data.users
      })
      .catch((err) => console.error(err))
  }

  openModal = () => {
    this.getRequestsFilters('requestTypes')
    this.isModalOpen = true
  }

  openModalContractCreate = () => {
    this.isModalContractCreateOpen = true
    this.btnCreateNewContract = true
    this.contractFactory = undefined
    this.contractNumber = ''
    this.contractPart = ''
  }

  openModalSendNotification = (e) => {
    this.isModalSendNotificationOpen = true
    this.btnSendInModalSendNotifcation = true
    this.contractRequestContractId = e
  }

  openModalSignedInfo = () => {
    this.isModalSignedInfoOpen = true
  }

  closeModalSignedInfo = () => {
    this.isModalSignedInfoOpen = false
  }

  closeModalSendNotification = () => {
    document.querySelector('#comment').value = ''
    document.querySelector('#addFiles').value = ''

    this.isModalSendNotificationOpen = false
  }

  getMaxContractNumber = (factoryId) => {
    if (+factoryId > 0) {
      fetch('/api/node/v1/statuses/get_max_contract_number/' + factoryId)
        .then((response) => response.json())
        .then((response) => {
          if (response.hasOwnProperty('contractNumber')) {
            this.contractNumber = response.contractNumber
            this.contractPart = 1
            this.checkBtnCreateNewContract()
          } else {
            console.log('Ошибка при получении')
          }
        })
        .catch((err) => console.error(err))
    }
  }

  getMaxContractPart = (factoryId, contractNumber) => {
    if (factoryId > 0 && contractNumber > 0) {
      let data = {
        factoryId: factoryId,
        contractNumber: contractNumber,
      }
      axios
        .post(`/api/node/v1/statuses/get_max_contract_part`, data)
        .then(async (response) => {
          if (response.data.hasOwnProperty('contractConsignment')) {
            this.contractPart = response.data.contractConsignment
            this.checkBtnCreateNewContract()
          } else {
            console.log('Ошибка при получении')
          }
        })
        .catch((err) => console.error(err))
      axios
        .post('/api/node/v1/contracts/check', {
          factory_id: factoryId,
          number: contractNumber,
        })
        .then(async (response) => {
          this.isContractExist = response.data?.data?.exist
          this.btnCreateNewContract = this.isContractExist ? true : false
        })
    }
  }

  getContractSignedInfo = (contractRequestContractId) => {
    if (+contractRequestContractId > 0) {
      fetch(
        `/api/node/v1/statuses/getContractSignedInfo/${+contractRequestContractId}`
      )
        .then((response) => response.json())
        .then((response) => {
          if (
            response.hasOwnProperty('signedDate') &&
            response.hasOwnProperty('signedUser')
          ) {
            this.signedContractInfo = response
            this.openModalSignedInfo()
          } else {
            console.log('Ошибка при получении')
          }
        })
        .catch((err) => console.error(err))
    }
  }

  handleChangeShowStatistic = () => {
    this.showStatistics = !this.showStatistics
  }

  closeModal = () => {
    this.selectedProductGroup = []
    this.requestType = null
    this.isModalOpen = false
  }

  closeModalContractCreate = () => {
    this.isModalContractCreateOpen = false
    this.extensionVisible = false
    this.isContractExist = false
  }

  closeRequestModal = () => {
    this.search = undefined
    this.currentFileLoading = null
    this.isModalRequestOpen = false
    this.commentsLang = undefined
    this.getRequests()
  }

  clearFilters = () => {
    this.filters = {
      names: [],
      managers: [],
      engineers: [],
      brandManagers: [],
      requestTypes: [],
      requestStatuses: [],
      numbers: [],
      text: undefined,
      filterContracts: [],
      filterManager: null,
      filterModels: [],
      productGroups: [],
      filterWeekNumber: undefined,
      createdAt: null,
    }
    this.page = 1
    for (let i = 0; i < this.filtersParams.length; i++) {
      this.filtersParams[i].opened = false
      this.filtersParams[i].checkedAll = false
      this.filtersParams[i].loaded = false
    }
    this.saveToStorage()
    this.getRequests()
  }

  saveNewRequest = () => {
    let requestName = document.getElementById('requestName').value
    let productGroup = []
    if (this.selectedProductGroup.length)
      productGroup = this.selectedProductGroup
    let requestType = null
    if (this.requestType !== null) requestType = this.requestType.value

    let comment = document.getElementById('addComment').value

    let file = document.getElementById('addRequestFile')

    //Проверка значений
    let checking =
      requestName.length <= 200 &&
      productGroup &&
      Number.isInteger(requestType) &&
      requestType < 99 &&
      comment.length <= 1000

    if (checking) {
      this.saveButtonDisabled = true
      this.saveButtonLoading = true
      axios
        .post('/api/node/v1/statuses/createRequest', {
          requestName: requestName,
          productGroup: productGroup,
          requestType: requestType,
          comment: comment,
        })
        .then((response) => {
          //Загрузка файлов

          if (response.hasOwnProperty('data')) {
            let type = 'request'
            let typeId = +response.data.requestId

            for (let i = 0; i < file.files.length; i++) {
              let data = new FormData()
              data.append('file', file.files[i])
              data.append('parentId', null)
              data.append(
                'createRequestNotification',
                JSON.stringify(response.data.mailData)
              )

              axios
                .post('/api/node/v1/file/upload/' + type + '/' + typeId, data)
                .then((response) => {
                  this.closeModal()
                  this.selectedProductGroup = []
                  this.requestType = null
                  this.saveButtonLoading = false
                  this.getRequests()
                  if (response) {
                    this.openNotificationWithIcon(
                      'success',
                      'Request saved successfully!'
                    )
                  } else {
                    this.openNotificationWithIcon('error', 'File not Saved!')
                  }
                })
            }
          }
        })
        .then(() => {
          this.getRequests()
        })
        .catch((error) => {
          console.log(error)
        })
      this.saveButtonDisabled = false
    }
  }

  changeRequestType = (e) => {
    this.requestType = e
    this.checkSaveButton()
  }

  changeProductGroup = (e) => {
    this.selectedProductGroup = e
    this.checkSaveButton()
  }

  handleChangeModelGroupsTree = (e) => {
    this.selectedProductGroup_tree = e
    this.checkSaveButton()
  }

  //Проверка полей ввода перед сохранением (SMART)
  checkSaveButton = () => {
    let requestName = document.getElementById('requestName').value
    let requestType = this.requestType
    let productGroup = this.selectedProductGroup
    let file = document.getElementById('addRequestFile')

    if (
      requestName &&
      requestType !== null &&
      productGroup.length !== 0 &&
      file.files.length
    ) {
      this.saveButtonDisabled = false
    } else {
      if (!this.saveButtonDisabled) this.saveButtonDisabled = true
    }
  }
  previewFile = (type, index, indexClickArrayComments) => {
    const fileMapping = {
      request: this.activeFullRequest.requestFiles,
      price: this.activeFullRequest.prices,
      test: this.activeFullRequest.testFiles,
      split: [this.activeFullRequest.splitFile],
      requestcomment:
        this.activeFullRequest.comments[+indexClickArrayComments]?.files,
    }

    this.filesPreview = fileMapping[type]
    this.arrayLengthPreview = fileMapping[type]?.length
    this.indexPreview = index
    this.visiblePreview = true
    this.typePreview = type
  }
  downloadFile = (fileId, type, filename, index, indexClickArrayComments) => {
    this.downloadFileLoading = true
    this.currentFileLoading = `${fileId}-${type}-${filename}`
    const fileObject = {
      fileId: fileId,
      type: type,
      filename: filename,
      progress: 0,
      status: false,
    }
    this.downloadFileProgresBar.push(Object.assign({}, fileObject))
    axios
      .get(`/api/node/v1/file/get/${type}/${fileId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
        onDownloadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded
          const total = progressEvent.total
          const progressPercentage = Math.round((loaded / total) * 100)
          // Находим объект файла в массиве и обновляем его прогресс
          const fileIndex = this.downloadFileProgresBar.findIndex(
            (file) => file.fileId === fileId
          )
          if (fileIndex !== -1) {
            this.downloadFileProgresBar[fileIndex].progress = progressPercentage
            this.downloadFileProgresBar[fileIndex].status = true
          }
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        // Удаляем объект файла из массива после завершения загрузки
        this.downloadFileProgresBar = this.downloadFileProgresBar.filter(
          (file) => file.fileId !== fileId
        )
        this.downloadFileLoading = false
        this.currentFileLoading = null
      })
      .catch((error) => {
        console.error(`Error downloading file ${filename}:`, error)
        // Устанавливаем статус файла в "exception"
        const fileIndex = this.downloadFileProgresBar.findIndex(
          (file) => file.fileId === fileId
        )
        if (fileIndex !== -1) {
          this.downloadFileProgresBar[fileIndex].status = 'exception'
        }
        // Удаляем объект файла из массива через 5 секунд
        setTimeout(() => {
          this.downloadFileProgresBar = this.downloadFileProgresBar.filter(
            (file) => file.fileId !== fileId
          )
        }, 5000)
        this.downloadFileLoading = false
        this.currentFileLoading = null
      })
  }

  downloadFileTask = (fileId, type, filename) => {
    axios
      .get(`/api/node/v1/task/file/get/${fileId}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
  }

  openFullRequest = async (id) => {
    this.isDisabledRequestCommentButton = true
    let response = await fetch(
      `/api/node/v1/statuses/getOneRequest/${id}?lang=${this.commentsLang}`
    )
    response = await response.json()
    if (response.request.hasOwnProperty('id')) {
      await fetch(`/api/node/v1/notices/updateAccessByObject/${id}`)
      this.activeFullRequest = response.request
      this.saveActiveFullRequest = response.request?.comments
      this.contracts = response.contractList
      this.splitUploading = false
      this.currentFileLoading = null
      let isUserAccess

      if (
        this.userTypeId === 3 ||
        this.userTypeId === 10 ||
        this.userTypeId === 11
      ) {
        isUserAccess = response.request.productGroups.some((productGroup) =>
          productGroup.users.some((user) => user.id === this.userId)
        )
        if (isUserAccess) {
          this.accessPO = false
          this.isModalRequestOpen = true
        } else {
          this.accessPO = true
        }
      } else {
        this.accessPO = false
        this.isModalRequestOpen = true
      }

      // if (isUserAccess || this.userTypeId === 1) {
      //   this.accessPO = false
      //   this.isModalRequestOpen = true
      // } else {
      //   this.accessPO = true
      // }

      //Обновление поля комментария в списке заявок (удаление выделения текста)
      this.filteredRequests.forEach((item) => {
        if (item.id === id) {
          item.isNewComment = false
        }
      })
      let temp = [...this.filteredRequests]
      this.filteredRequests = temp
    }
  }

  deleteRequest = (id) => {
    fetch('/api/node/v1/statuses/deleteOneRequest/' + id)
      .then((response) => response.json())
      .then((response) => {
        this.getRequests()
        this.openNotificationWithIcon(
          'success',
          'Request deleted successfully!'
        )
      })
      .catch((err) => console.error(err))
  }

  changeTaskModelOption = (productGroup) => {
    if (productGroup > 0)
      fetch('/api/node/v1/filter/model/' + productGroup)
        .then((response) => response.json())
        .then((response) => {
          if (response.length >= 0 && Array.isArray(response)) {
            this.models = response
          } else {
            console.log('Ошибка при сохранении')
          }
        })
        .catch((err) => console.error(err))
  }

  changeFilterValue = (value, type) => {
    this.filters[type] = value
  }

  acceptFilters = (value, type, update = true) => {
    if (update) {
      this.filters[type] = value
      if (type === 'createdAt') {
        this.filters.filterWeekNumber = undefined
      }
    }
    if (type === 'filterWeekNumber') {
      this.filters.createdAt = null
    }
    this.page = 1

    this.saveToStorage()
    this.getRequests()
  }

  getChildrenProductGroup = (productGroup) => {
    let tmp = []
    if (productGroup.children.length > 0)
      for (const item of productGroup.children) {
        tmp.push(item.id)
        tmp = tmp.concat(this.getChildrenProductGroup(item))
      }
    return tmp
  }

  renderRequestPrices = (
    {
      id,
      original_name,
      file_link,
      created_at,
      userSurname,
      userName,
      brandStatus,
      headStatus,
      approveBrandDate,
      approveBrandUser,
      approveRequestDate,
      approveRequestUser,
      requestStatus,
      loading,
    },
    index
  ) => {
    let date = new Date(created_at)
    let formattedDate = date.toLocaleString('ru')

    if (approveBrandDate !== null) {
      let brandDate = new Date(approveBrandDate)
      approveBrandDate = brandDate.toLocaleString('ru')
    }
    if (approveRequestDate !== null) {
      let requestDate = new Date(approveRequestDate)
      approveRequestDate = requestDate.toLocaleString('ru')
    }

    let approvedBrandManager = brandStatus
    let approvedRequestHead = requestStatus

    return (
      <tr key={`priceRowTable${id}`}>
        <td
          title={`${userSurname} ${userName}`}
          onClick={() => this.previewFile('price', index)}
        >
          <div className={styles.requestTestFile}>
            <i className="fa fa-file-excel-o" />
            {original_name} {formattedDate}
          </div>
          {this.downloadFileProgresBar.find((file) => {
            return file.fileId === id && file.status === true
          }) && (
            <div>
              <Progress
                percent={
                  this.downloadFileProgresBar.find((file) => file.fileId === id)
                    ?.progress
                }
                size="small"
              />
            </div>
          )}
        </td>
        <td
          title={approveBrandUser}
          style={{
            background:
              approvedBrandManager === 'approve'
                ? confirmColor
                : approvedBrandManager === null
                ? 'none'
                : rejectColor,
            verticalAlign: 'middle',
          }}
        >
          {approveBrandDate}
          {this.activeFullRequest.canApprovePriceBrand && !approveBrandDate && (
            <Popconfirm
              placement="top"
              title={`Confirm this file?`}
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.approve(id, 'file', 'approve')}
            >
              <ButtonLink
                loading={loading}
                className="btn btn-success"
                disabled={this.fileAlreadyInProgress(id)}
                size="sm"
                style={{ marginRight: '5px' }}
              >
                <i className="fa fa-check-circle" />
              </ButtonLink>
            </Popconfirm>
          )}
          {this.activeFullRequest.canApprovePriceBrand && !approveBrandDate && (
            <Popconfirm
              placement="top"
              title={`Reject this file?`}
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.approve(id, 'file', 'reject')}
            >
              <ButtonLink
                loading={loading}
                className="btn btn-danger"
                disabled={this.fileAlreadyInProgress(id)}
                size="sm"
              >
                {' '}
                <i className="fa fa-ban" />
              </ButtonLink>
            </Popconfirm>
          )}
        </td>
        <td
          title={approveRequestUser}
          style={{
            background:
              approvedRequestHead === 'approve'
                ? confirmColor
                : approvedRequestHead === null
                ? 'none'
                : rejectColor,
            verticalAlign: 'middle',
          }}
        >
          {approveRequestDate}
          {this.activeFullRequest.canApprovePriceRequest &&
            !approveRequestDate && (
              <Popconfirm
                placement="top"
                title={`Confirm this file?`}
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.approve(id, 'file', 'approve')}
              >
                <ButtonLink
                  loading={loading}
                  className="btn btn-success"
                  size="sm"
                  disabled={this.fileAlreadyInProgress(id)}
                  style={{ marginRight: '5px' }}
                >
                  <i className="fa fa-check-circle" />
                </ButtonLink>
              </Popconfirm>
            )}
          {this.activeFullRequest.canApprovePriceRequest &&
            !approveRequestDate && (
              <Popconfirm
                placement="top"
                title={`Reject this file?`}
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.approve(id, 'file', 'reject')}
              >
                <ButtonLink
                  loading={loading}
                  className="btn btn-danger"
                  disabled={this.fileAlreadyInProgress(id)}
                  size="sm"
                >
                  <i className="fa fa-ban" />
                </ButtonLink>
              </Popconfirm>
            )}
        </td>
        {/* {this.activeFullRequest.canLoadPrice && ( */}
        <td style={{ verticalAlign: 'middle' }}>
          <div style={{ display: 'flex' }}>
            <Button
              color="primary"
              size={'sm'}
              style={{ marginRight: '10px' }}
              onClick={() =>
                !this.fileAlreadyInProgress(id) &&
                this.downloadFile(id, 'price', original_name)
              }
              disabled={this.fileAlreadyInProgress(id)}
            >
              <i className="fa fa-download" />
            </Button>
            {headStatus === null &&
              brandStatus === null &&
              this.activeFullRequest.canLoadPrice && (
                <Popconfirm
                  placement="right"
                  title={() => (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      Delete file&nbsp;
                      {original_name.length > 58 ? (
                        <Tooltip title={original_name}>
                          <span className={styles.titleTooltip}>
                            {original_name}
                          </span>
                        </Tooltip>
                      ) : (
                        original_name
                      )}
                      ?
                    </span>
                  )}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => this.deleteFile(id, 'price', original_name)}
                >
                  <Button
                    className="btn btn-danger"
                    size={'sm'}
                    disabled={this.fileAlreadyInProgress(id)}
                  >
                    <i className="fa fa-trash-o" />
                  </Button>
                </Popconfirm>
              )}
          </div>
        </td>
        {/* )} */}
      </tr>
    )
  }

  fileIsLoading = (id, type, name) => {
    const fileTag = `${id}-${type}-${name}`
    return this.currentFileLoading === fileTag
      ? this.downloadFileLoading
      : false
  }

  renderTestFiles = ({ id, original_name, created_at, user }, index) => {
    let date = new Date(created_at)
    let formattedDate = date.toLocaleString('ru')

    return (
      <tr key={`priceRowTable${id}`}>
        <td>
          <div
            style={!this.fileAlreadyInProgress(id) ? { cursor: 'pointer' } : {}}
            onClick={() => this.previewFile('test', index)}
          >
            <div className={styles.requestTestFile}>
              <i className="fa fa-file-excel-o" />
              {original_name}
            </div>
            {this.downloadFileProgresBar.find((file) => {
              return file.fileId === id && file.status === true
            }) && (
              <div>
                <Progress
                  percent={
                    this.downloadFileProgresBar.find(
                      (file) => file.fileId === id
                    )?.progress
                  }
                  size="small"
                />
              </div>
            )}
          </div>
        </td>
        <td>&nbsp;{formattedDate}</td>
        <td>
          {user.name} {user.surname}
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <Button
            color="primary"
            size={'sm'}
            onClick={() =>
              !this.fileAlreadyInProgress(id) &&
              this.downloadFile(id, 'test', original_name)
            }
            disabled={this.fileAlreadyInProgress(id)}
          >
            <i className="fa fa-download" />
          </Button>
        </td>
      </tr>
    )
  }

  renderRequestProductGroups = (
    { id, article, name, managers, brands, engineers, users },
    key
  ) => {
    let label = article ? `${article} ${name}` : name

    return (
      <tr key={key}>
        <td>{label}</td>
        <td>{managers.map(this.renderRequestProductGroupUsers)}</td>
        <td>{brands.map(this.renderRequestProductGroupUsers)}</td>
        <td>{engineers.map(this.renderRequestProductGroupUsers)}</td>
      </tr>
    )
  }

  renderRequestLinks = ({ id, theme, fileId, fileName, fileType }, key) => {
    let url = `/tasks/all?&id=${id}`
    return (
      <tr key={key}>
        <td>
          <a href={url} target="blank">
            {theme}
          </a>
        </td>
        <td>
          <ButtonLink
            type="link"
            onClick={() => this.downloadFileTask(fileId, fileType, fileName)}
            style={{
              marginLeft: '10px',
              color: '#20a8d8',
              cursor: 'pointer',
            }}
          >
            <i style={{ marginLeft: '10px' }} className="cui-cloud-download" />
            &nbsp;{fileName}
          </ButtonLink>
        </td>
      </tr>
    )
  }

  renderRequestProductGroupUsers = ({ id, name, surname }) => {
    return (
      <span key={'RequestProductGroupsUsers' + id} style={{ margin: '5px' }}>
        {name + ' ' + surname}
      </span>
    )
  }

  renderRequestContracts = ({
    id,
    contract_id,
    contractName,
    status,
    created_at,
    updated_at,
    approved_user_id,
    createUserName,
    createUserSurname,
    approveUserName,
    signed,
    url,
    approveUserSurname,
    contractFileUserSurname,
    contractFileUserName,
    file,
    t,
  }) => {
    let loadFormatDate = null

    if (created_at !== null) {
      let loadDate = new Date(created_at)
      loadFormatDate = loadDate.toLocaleString('ru')
    }

    return (
      <tr key={`contractRowTable${id}`}>
        <td>
          <a
            href={`/react/contracts/data?&id=${contract_id}&tab=specification`}
            target="blank"
          >
            {contractName}
          </a>
        </td>
        <td title={`${createUserSurname} ${createUserName}`}>
          {loadFormatDate}
        </td>
        <td>
          {signed ? (
            this.activeFullRequest.canAddContracts ? (
              <Tag className={styles.tagContracts} color="success">
                {t('w_signed_contract')}
                <CheckCircleOutlined
                  className={styles.tagContracts__iconCheck}
                />
              </Tag>
            ) : (
              ''
            )
          ) : this.activeFullRequest.canAddContracts ? (
            <Tag className={styles.tagContracts} color="default">
              {t('w_awaiting_signing ')}
            </Tag>
          ) : (
            ''
          )}
        </td>
        {this.activeFullRequest.canAddContracts && (
          <td style={{ verticalAlign: 'middle' }}>
            {status === null && (
              <Popconfirm
                placement="right"
                title={`Delete contract ${contractName}?`}
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.deleteContract(id)}
              >
                <Button className="btn btn-danger" size={'sm'}>
                  <i className="fa fa-trash-o" />
                </Button>
              </Popconfirm>
            )}
          </td>
        )}
      </tr>
    )
  }

  //Цены
  changeFileInput = (id, state) => {
    let fileLength = document.getElementById(id).files.length
    let disabled = true
    if (fileLength) {
      disabled = false
    }
    this[state] = disabled
  }

  //Контракты
  changeRequestContract = (e) => {
    this.selectedContract = e
    this.isDisabledAddContract = e === null
  }

  //Контракты для фильтра контрактов
  renderContracts = ({ id, name }) => (
    <Option key={'selectContract' + id} value={id}>
      {name}
    </Option>
  )

  //Модели для фильтра моделей
  renderModels = ({ id, name }) => (
    <Option key={'selectModel' + id} value={id}>
      {name}
    </Option>
  )

  //Комментарии активной заявки
  renderComments = (
    { id, comment, files, userSurname, userName, userId, avatar, created_at },
    indexClickArrayComments
  ) => {
    let user = userName + ' ' + userSurname
    let date = new Date(created_at)
    let formatDate = date.toLocaleString('ru')
    let src = avatar ? avatar : ''
    return (
      <div key={'requestCommentsActive' + id}>
        <small className="text-muted">
          <Avatar src={src} />
          &nbsp;<b style={{ color: 'black' }}>{user}</b>
          <i className="fa fa-clock-o" style={{ marginLeft: '10px' }} />
          &nbsp;{formatDate}
        </small>
        <br />
        {comment !== '<p><br></p>' && (
          <div
            style={{ textIndent: '20px', marginLeft: '15px' }}
            dangerouslySetInnerHTML={{ __html: comment }}
          ></div>
        )}
        {files.map((el, index) =>
          this.renderCommentFiles(el, index, indexClickArrayComments)
        )}
      </div>
    )
  }

  changeCommentInput = (_) => {
    let text = document
      .getElementById('activeRequestComment')
      .getElementsByClassName('ql-editor')[0].innerText

    let disabled = false

    if (text.trim() === '' || text.length > 5000) {
      disabled = true
    }
    if (this.uploads && this.uploads.length > 0) {
      disabled = false
    }

    if (this.isDisabledRequestCommentButton !== disabled) {
      this.isDisabledRequestCommentButton = disabled
    }
  }

  changeComment = (_) => {
    const value = document.querySelector('#comment').value
    let disabled = false

    if (value.trim() === '' || value.length < 1) disabled = true

    this.btnSendInModalSendNotifcation = disabled
  }

  saveNewComment = async () => {
    try {
      let requestId = this.activeFullRequest.id
      let comment = document
        .getElementById('activeRequestComment')
        .getElementsByClassName('ql-editor')[0]
        .innerHTML.trim()

      //Проверка значений
      let checking =
        requestId < 99999999999 &&
        Number.isInteger(requestId) &&
        comment.length > 0

      if (checking) {
        this.isDisabledRequestCommentButton = true
        const formData = new FormData()
        for (let i = 0; i < this.uploads.length; i++) {
          formData.append('file', this.uploads[i])
        }
        formData.append(`requestId`, requestId)
        formData.append(`comment`, comment)
        formData.append(`user_id`, this.userId)
        const response = await axios.post(
          '/api/node/v1/statuses/createComment/requestcomment',
          formData
        )
        if (response.data.hasOwnProperty('message')) {
          if (response.data.commentId !== null) {
            //Отчистка поля комментария и input file
            document.getElementById('requestInnerComment').value = ''
            document
              .getElementById('activeRequestComment')
              .getElementsByClassName('ql-editor')[0].innerHTML = ''
            this.uploads = []
            await this.openFullRequest(requestId)
            this.openNotificationWithIcon(
              response.data.type,
              response.data.message
            )
          }
        }
      } else {
        this.openNotificationWithIcon('error', 'Access denied')
        this.isDisabledRequestCommentButton = false
      }
    } catch (error) {
      console.log(error)
    }
  }
  changeCommentsLang = async (lang) => {
    this.loadingTranslate = true

    localStorage.setItem('commentsLang', lang)

    try {
      this.commentsLang = lang
      let requestId = this.activeFullRequest.id
      await this.openFullRequest(requestId)
    } catch (error) {
      console.error('Error translating comments:', error)
    } finally {
      this.loadingTranslate = false
    }
  }

  addNewContract = (_) => {
    this.isDisabledAddContract = true
    let requestId = this.activeFullRequest.id
    let contract = this.selectedContract
    let contractId = contract !== null ? contract.id : null

    let saveData = {
      requestId: requestId,
      contractId: contractId,
    }

    //Проверка значений
    let checking =
      requestId < 99999999999 &&
      Number.isInteger(requestId) &&
      contractId !== null &&
      contractId < 99999999999 &&
      Number.isInteger(+contractId)

    if (checking) {
      this.isDisabledAddContract = true
      axios
        .post('/api/node/v1/statuses/addContract', saveData)
        .then(async (response) => {
          if (response.data.hasOwnProperty('message')) {
            this.openNotificationWithIcon(
              response.data.type,
              response.data.message
            )
            this.selectedContract = null
          } else {
            this.openNotificationWithIcon('error', 'Access denied')
            this.isDisabledAddContract = false
          }
          await this.openFullRequest(requestId)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    this.isDisabledAddContract = false
  }

  createNewContract = (_) => {
    let requestId = this.activeFullRequest.id
    let contractFactory = +this.contractFactory
    let contractNumber = +this.contractNumber
    let contractPart = +this.contractPart

    let saveData = {
      contractFactory: contractFactory,
      contractNumber: contractNumber,
      contractPart: contractPart,
      requestId: requestId,
    }

    //Проверка значений
    let checking = contractFactory > 0 && contractNumber > 0 && contractPart > 0

    if (checking) {
      this.btnCreateNewContract = true
      axios
        .post('/api/node/v1/statuses/createContract', saveData)
        .then(async (response) => {
          if (response.data.hasOwnProperty('message')) {
            this.openNotificationWithIcon(
              response.data.type,
              response.data.message
            )
            this.selectedContract = null
            this.contracts = response.data.contracts
            this.closeModalContractCreate()
            this.extensionVisible = false
            this.isContractExist = false
            await this.openFullRequest(requestId)
          } else {
            this.openNotificationWithIcon('error', 'Access denied')
          }
          this.isDisabledCreateContract = false
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      this.openNotificationWithIcon('error', 'Input correct contract number')
    }
  }

  deleteContract = (id) => {
    let requestId = this.activeFullRequest.id

    let data = {
      requestId: requestId,
      contractId: id,
    }

    //Проверка значений
    let checking =
      requestId < 99999999999 &&
      Number.isInteger(requestId) &&
      id < 99999999999 &&
      Number.isInteger(+id)

    if (checking) {
      this.isDisabledRequestCommentButton = true
      axios
        .post('/api/node/v1/statuses/deleteContract', data)
        .then(async (response) => {
          if (response.data.hasOwnProperty('message')) {
            this.openNotificationWithIcon(
              response.data.type,
              response.data.message
            )
          } else {
            this.openNotificationWithIcon('error', 'Access denied')
            this.isDisabledRequestCommentButton = false
          }
          await this.openFullRequest(requestId)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
    })
  }

  uploadFile = async (
    inputId,
    type,
    loadButton,
    loading,
    messageType,
    parentId,
    fileDrop
  ) => {
    const typeId = this.activeFullRequest.id
    this[loading] = true
    this[loadButton] = true

    await this.uploadFileToServer(
      inputId,
      type,
      typeId,
      loadButton,
      loading,
      messageType,
      parentId,
      fileDrop
    )
  }

  uploadFileToServer = async (
    inputId,
    type,
    typeId,
    loadButton,
    loading,
    messageType,
    parentId,
    fileDrop
  ) => {
    let file
    let data = new FormData()
    if (fileDrop) {
      file = fileDrop
      data.append('file', file)
      data.append('parentId', parentId)
    } else {
      file = document.getElementById(inputId)
      data.append('file', file.files[0])
      data.append('parentId', parentId)
    }
    axios
      .post('/api/node/v1/file/upload/' + type + '/' + typeId, data)
      .then(async (response) => {
        file.value = null
        this[loading] = false
        await this.openFullRequest(typeId)
        if (response) {
          if (type === 'price' && this.activeFullRequest.status_id === 1) {
            this.goToNextStage()
            this.getRequests()
          }
          this.openNotificationWithIcon(
            'success',
            messageType + ' saved successfully!'
          )
        } else {
          this.openNotificationWithIcon('error', 'File not Saved!')
        }
      })
  }

  deleteFile = async (fileId, type, name) => {
    try {
      const response = await fetch(`/api/node/v1/file/delete/${type}/${fileId}`)
      console.log(
        'this.activeFullRequest.id======>deleteFile',
        this.activeFullRequest.id
      )
    } catch (err) {
      console.error(err)
    } finally {
      await this.openFullRequest(this.activeFullRequest.id)
    }
  }

  fileAlreadyInProgress(id) {
    return this.downloadFileProgresBar.some((file) => file.fileId === id)
  }
  renderRequestFiles = (
    { id, original_name, created_at, userSurname, userName },
    type = 'request',
    index
  ) => {
    let date = new Date(created_at)
    let formattedDate = date.toLocaleString('ru')

    return (
      <Row key={`filePriceRows${id}`}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <ButtonLink
            title={`${userSurname} ${userName}`}
            style={{
              margin: '0 10px 10px 10px',
              height: 'fit-content',
              display: 'flex',
              flexGrow: 1,
            }}
            onClick={() => this.previewFile(type, index)}
          >
            <div className={styles.requestFileWrapper}>
              <div className={styles.requestFileWrapper__fileName}>
                <i className="fa fa-file-excel-o" />
                {original_name}
              </div>
              {formattedDate}
              {this.downloadFileProgresBar.find((file) => {
                return file.fileId === id && file.status === true
              }) && (
                <div>
                  <Progress
                    percent={
                      this.downloadFileProgresBar.find(
                        (file) => file.fileId === id
                      )?.progress
                    }
                    size="small"
                  />
                </div>
              )}
            </div>
          </ButtonLink>

          <>
            <Button
              color="primary"
              style={{ margin: '0 10px 10px 0', height: '40px' }}
              onClick={() => this.downloadFile(id, type, original_name)}
              disabled={this.fileAlreadyInProgress(id)}
            >
              <i className="fa fa-download" />
            </Button>
          </>
          {this.activeFullRequest.canLoadRequest && (
            <>
              <Popconfirm
                placement="right"
                title={() => (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    Delete file&nbsp;
                    {original_name.length > 58 ? (
                      <Tooltip title={original_name}>
                        <span className={styles.titleTooltip}>
                          {original_name}
                        </span>
                      </Tooltip>
                    ) : (
                      original_name
                    )}
                    ?
                  </span>
                )}
                okText="Да"
                cancelText="Нет"
                onConfirm={() => this.deleteFile(id, type, original_name)}
              >
                <Button
                  className="btn btn-danger"
                  style={{ margin: '0 10px 10px 0', height: '40px' }}
                  disabled={this.fileAlreadyInProgress(id)}
                >
                  <i className="fa fa-trash-o" />
                </Button>
              </Popconfirm>
            </>
          )}
        </div>
      </Row>
    )
  }

  renderUsers = ({ id, label }) => (
    <Option key={'selectUsers' + id} value={id + '_' + label}>
      {label}
    </Option>
  )

  approve = async (fileId, type, status) => {
    let price = this.activeFullRequest.prices.find(
      (price) => price.id === fileId
    )
    if (price) price.loading = true

    fetch(`/api/node/v1/statuses/approve/${type}/${fileId}/${status}`)
      .then((response) => response.json())
      .then(async (response) => {
        if (response.hasOwnProperty('status')) {
          if (response.status !== 'file was deleted') return
        }
        await this.openFullRequest(this.activeFullRequest.id)
        price.loading = false
      })
      .catch((err) => {
        console.error(err)
        price.loading = false
      })
  }

  approveContract = async (id, status) => {
    let requestId = this.activeFullRequest.id
    let saveData = {
      id: id,
      status: status,
    }

    //Проверка значений
    let checking =
      id < 99999999999 &&
      Number.isInteger(id) &&
      (status === 'approve' || status === 'reject')

    if (checking) {
      this.isDisabledAddContract = true
      axios
        .post('/api/node/v1/statuses/approveContract', saveData)
        .then(async (response) => {
          if (response.data.hasOwnProperty('message')) {
            this.openNotificationWithIcon(
              response.data.type,
              response.data.message
            )
          } else {
            this.openNotificationWithIcon('error', 'Access denied')
          }
          await this.openFullRequest(requestId)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  saveFile = async (files, contractRequestContractId) => {
    for (let i = 0; i < files.files.length; i++) {
      let data = new FormData()
      data.append('file', files.files[i])
      await axios
        .post(
          '/api/node/v1/file/upload/contractRequestContract/' +
            contractRequestContractId,
          data
        )
        .then((response) => {})
        .catch((err) => console.error(err))
    }
  }

  signContract = async () => {
    let requestId = +this.activeFullRequest.id
    let contractRequestContractId = +this.contractRequestContractId
    let comment = document.querySelector('#comment').value.trim()
    let files = document.querySelector('#addFiles')
    let saveData = {
      requestId: requestId,
      contractRequestContractId: contractRequestContractId,
      comment: comment,
    }

    //Проверка значений
    let checking =
      contractRequestContractId < 99999999999 &&
      contractRequestContractId > 0 &&
      comment.length > 0 &&
      requestId > 0

    if (checking) {
      this.loadingSignContract = true

      axios
        .post('/api/node/v1/statuses/signContract', saveData)
        .then(async (response) => {
          this.loadingSignContract = false
          if (response.data.hasOwnProperty('message')) {
            //Сохранение файлов
            await this.saveFile(files, contractRequestContractId)

            // Отправка уведомления на почту
            await axios
              .post('/api/node/v1/statuses/signContractNotification', saveData)
              .then(async (response) => {
                this.loadingSignContract = false
                if (response.data.hasOwnProperty('message')) {
                  this.openNotificationWithIcon(
                    response.data.type,
                    response.data.message
                  )
                } else {
                  this.openNotificationWithIcon('error', 'Access denied')
                }
                await this.openFullRequest(requestId)
              })
              .catch((error) => {
                console.log(error)
              })
            this.openNotificationWithIcon(
              response.data.type,
              response.data.message
            )
          } else {
            this.openNotificationWithIcon('error', 'Access denied')
          }
          await this.openFullRequest(requestId)
        })
        .catch((error) => {
          console.log(error)
        })
      this.closeModalSendNotification()
    }
  }

  signContractWithoutNotification = async () => {
    let requestId = +this.activeFullRequest.id
    let contractRequestContractId = +this.contractRequestContractId
    let saveData = {
      requestId: requestId,
      contractRequestContractId: contractRequestContractId,
    }

    //Проверка значений
    let checking =
      contractRequestContractId < 99999999999 && contractRequestContractId > 0

    if (checking) {
      this.loadingSignContract = true

      axios
        .post('/api/node/v1/statuses/signContractWithoutNotification', saveData)
        .then(async (response) => {
          this.loadingSignContract = false
          if (response.data.hasOwnProperty('message')) {
            this.openNotificationWithIcon(
              response.data.type,
              response.data.message
            )
          } else {
            this.openNotificationWithIcon('error', 'Access denied')
          }
          await this.openFullRequest(requestId)
        })
        .catch((error) => {
          console.log(error)
        })
      this.closeModalSendNotification()
    }
  }

  customizeCell = (args) => {
    if (args.typeName === 'Regular') {
      if ((args.ab > 5 || args.ab === null) && args.status_id === 1) {
        return 'confirmColor'
      }

      if ((args.bc > 3 || args.bc === null) && args.status_id === 2) {
        return 'waitColor'
      }

      if ((args.cd > 3 || args.cd === null) && args.status_id === 3) {
        return 'progressColor'
      }

      if ((args.ae > 15 || args.ae === null) && args.status_id === 4) {
        return 'needChecking'
      }
    }

    if (args.typeName === 'Seasonal' || args.typeName === 'New') {
      if ((args.ab > 20 || args.ab === null) && args.status_id === 1) {
        return 'confirmColor'
      }

      if ((args.bc > 7 || args.bc === null) && args.status_id === 2) {
        return 'waitColor'
      }

      if ((args.cd > 7 || args.cd === null) && args.status_id === 3) {
        return 'progressColor'
      }

      if ((args.ae > 45 || args.ae === null) && args.status_id === 4) {
        return 'needChecking'
      }
    }
  }

  goToNextStage = async () => {
    let requestId = this.activeFullRequest.id
    let stage = this.activeFullRequest.stage
    let data = {
      id: requestId,
      stage: stage,
    }

    //Проверка значений
    let checking =
      requestId < 99999999999 &&
      Number.isInteger(requestId) &&
      stage < 9 &&
      Number.isInteger(stage)

    if (checking) {
      this.isDisabledAddContract = true
      axios
        .post('/api/node/v1/statuses/goToNextStage', data)
        .then(async (response) => {
          if (response.data.hasOwnProperty('message')) {
            this.openNotificationWithIcon(
              response.data.type,
              response.data.message
            )
          } else {
            this.openNotificationWithIcon('error', 'Access denied')
          }
          await this.openFullRequest(requestId)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  changeRequestNotify = async (e) => {
    let data = { requestId: this.activeFullRequest.id, sendMail: e }

    this.loadingChangeRequestNotify = true
    axios
      .post('/api/requests/post/notify', data)
      .then(async (response) => {
        if (response.data.hasOwnProperty('status')) {
          this.openNotificationWithIcon(
            response.data.status,
            response.data.message
          )
        } else {
          this.openNotificationWithIcon(
            'error',
            'status not saved, reload page!'
          )
        }
        await this.openFullRequest(this.activeFullRequest.id)
        this.loadingChangeRequestNotify = false
      })
      .catch((error) => {
        this.loadingChangeRequestNotify = false
        console.log(error)
      })
  }

  renderCommentFiles = (
    { id, original_name, author },
    index,
    indexClickArrayComments
  ) => {
    return (
      <div
        key={'commentFilesActive' + id}
        className={styles.commentFileContainer}
      >
        <ButtonLink
          type="link"
          onClick={() =>
            this.previewFile('requestcomment', index, indexClickArrayComments)
          }
          style={{ color: '#20a8d8', cursor: 'pointer' }}
        >
          <div className={styles.requestCommentFile}>
            {original_name.length > 150 ? (
              <Tooltip title={original_name}>
                <div className={styles.requestCommentFileWrapper}>
                  <div className={styles.titleTooltipComment}>
                    {original_name}
                  </div>
                </div>
              </Tooltip>
            ) : (
              original_name
            )}
          </div>
          {this.downloadFileProgresBar.find((file) => {
            return file.fileId === id && file.status === true
          }) && (
            <div style={{ width: '200px' }}>
              <Progress
                percent={
                  this.downloadFileProgresBar.find((file) => file.fileId === id)
                    ?.progress
                }
                size="small"
              />
            </div>
          )}
        </ButtonLink>
        <span
          style={
            !this.fileAlreadyInProgress(id)
              ? { color: '#20a8d8', cursor: 'pointer', marginRight: '5px' }
              : { cursor: 'not-allowed' }
          }
          onClick={() =>
            !this.fileAlreadyInProgress(id) &&
            this.downloadFile(id, 'requestcomment', original_name)
          }
        >
          <i className="cui-cloud-download" />
        </span>
        {author && (
          <Popconfirm
            placement="right"
            disabled={this.fileAlreadyInProgress(id)}
            title={() => (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                Delete file&nbsp;
                {original_name.length > 58 ? (
                  <Tooltip title={original_name}>
                    <span className={styles.titleTooltip}>{original_name}</span>
                  </Tooltip>
                ) : (
                  original_name
                )}
                ?
              </span>
            )}
            okText="Yes"
            cancelText="No"
            onConfirm={() =>
              this.deleteFile(id, 'requestcomment', original_name)
            }
          >
            <span
              style={
                !this.fileAlreadyInProgress(id)
                  ? { color: 'red', cursor: 'pointer' }
                  : { cursor: 'not-allowed' }
              }
            >
              <i className="cui-trash" />
            </span>
          </Popconfirm>
        )}
      </div>
    )
  }

  renderFactories = ({ id, abbreviation }) => {
    return (
      <Option key={'selectFactory' + id} value={id}>
        {abbreviation}
      </Option>
    )
  }

  changeContractFactory = (e) => {
    this.contractFactory = e
    this.contractNumber = ''
    this.contractPart = ''
    this.checkBtnCreateNewContract()

    if (+e > 0) {
      this.getMaxContractNumber(e)
      this.extensionVisible = true
    } else {
      this.extensionVisible = false
      this.isContractExist = false
    }
  }

  changeContractNumber = (e) => {
    this.contractNumber = e.target.value
    this.checkBtnCreateNewContract()
    if (+e.target.value > 0) {
      let factoryId = this.contractFactory
      this.getMaxContractPart(factoryId, e.target.value)
    }
  }

  checkBtnCreateNewContract = (_) => {
    let check = this.contractFactory > 0 && this.contractNumber > 0
    this.btnCreateNewContract = this.isContractExist || !check ? true : false
  }

  renderSignedFiles = ({ id, original_name }) => {
    return (
      <div key={'commentFilesActive' + original_name}>
        <ButtonLink
          type="link"
          onClick={() =>
            this.downloadFile(id, 'contractRequestContract', original_name)
          }
          style={{ marginLeft: '10px', color: '#20a8d8', cursor: 'pointer' }}
        >
          <i style={{ marginLeft: '10px' }} className="cui-cloud-download" />
          &nbsp;{original_name}
        </ButtonLink>
      </div>
    )
  }

  openEditModal = async () => {
    await this.getRequestsFilters('requestTypes')
    let groups = await this.activeFullRequest.productGroups.map((element) => {
      let label = element.article
        ? `${element.article} ${element.name}`
        : element.name

      return {
        label: label,
        value: element.product_group_id,
        id: element.id,
      }
    })

    let type = {
      id: this.activeFullRequest.type_id,
      value: this.activeFullRequest.type_id,
      label: this.activeFullRequest.typeName,
      name: this.activeFullRequest.typeName,
    }

    this.editName = this.activeFullRequest.theme
    this.editType = type
    this.editGroups = groups
    this.isEditModalOpen = true
  }

  closeEditModal = () => {
    this.isEditModalOpen = false
  }

  editNameFunc = (e) => {
    this.editName = e.target.value
    this.editSaveButtonDisabled =
      e.target.value === '' ||
      this.editType === '' ||
      this.editGroups.length === 0
  }

  editTypeFunc = (e) => {
    this.editType = e
    this.editSaveButtonDisabled =
      this.editName === '' || e === null || this.editGroups.length === 0
  }

  editGroup = (e) => {
    this.editGroups = e
    this.editSaveButtonDisabled =
      this.editName === '' || this.editType === null || e.length === 0
  }

  updateRequest = () => {
    let requestName = this.editName
    let productGroup = this.editGroups
    let requestType = this.editType.value

    let checking =
      requestName.length <= 200 &&
      productGroup &&
      Number.isInteger(requestType) &&
      requestType < 99

    if (checking) {
      this.editSaveButtonDisabled = true
      this.editSaveButtonLoading = true
      axios
        .post('/api/node/v1/statuses/updateRequest', {
          requestId: this.activeFullRequest.id,
          requestName: requestName,
          productGroup: productGroup,
          requestType: requestType,
        })
        .then((response) => {
          this.closeEditModal()
          this.editSaveButtonLoading = false
          this.editSaveButtonDisabled = false

          this.closeRequestModal()
          if (response.data.success) {
            this.openNotificationWithIcon(
              'success',
              'Request saved successfully!'
            )
          } else {
            this.openNotificationWithIcon('error', 'File not Saved!')
          }
        })
        .then(() => {
          this.getRequests()
        })
        .catch((error) => {
          console.log(error)
        })
      this.editSaveButtonDisabled = false
    }
  }

  changePage = (key, val) => {
    this[key] = val
    this.getRequests()
  }

  changeSort = (type) => {
    this.sort[type] = !this.sort[type]
    this.currentSort = type
    this.getRequests()
  }

  openFilter = (idx, type) => {
    for (let i = 0; i < this.filtersParams.length; i++) {
      this.filtersParams[i].opened = false
    }
    let users = [...this.users]
    this.filtersParams[idx].opened = true
    this.users = users
    if (!this.filtersParams[idx].loaded) this.getRequestsFilters(type)
  }

  closeFilter = (idx) => {
    let users = [...this.users]
    this.filtersParams[idx].opened = false
    this.users = users
  }

  filterFilters = (val, type, filterIdx) => {
    let users = [...this.users]

    this.filtersParams[filterIdx].input = val
    let regex = new RegExp(`.*${val}.*`)

    let temp = []
    for (let el of this[type]) {
      let string = el.label
      if (regex.test(string.toLowerCase()) || regex.test(string)) {
        temp.push(el.value)
      }
    }
    this.filteredFilters[type] = temp
    this.users = users
  }

  checkAll = (type, filterIdx) => {
    let users = [...this.users]
    this.filtersParams[filterIdx].checkedAll =
      !this.filtersParams[filterIdx].checkedAll

    for (let el of this[type]) {
      el.checked = this.filtersParams[filterIdx].checkedAll
    }

    this.users = users
  }

  clearFilter = (type, filterIdx) => {
    let users = [...this.users]
    this.filtersParams[filterIdx].checkedAll = false
    this.filterFilters('', type, filterIdx)
    for (let el of this[type]) {
      el.checked = false
    }
    this.acceptFilter(type, filterIdx)
    this.users = users
  }

  changeFilter = (value, idx, type, filterIdx) => {
    let users = [...this.users]
    this[type][idx].checked = value

    if (value) {
      this.filtersParams[filterIdx].checkedAll = true
      for (let el of this[type]) {
        if (!el.checked) {
          this.filtersParams[filterIdx].checkedAll = false
          break
        }
      }
    } else {
      this.filtersParams[filterIdx].checkedAll = false
    }
    this.users = users
  }

  acceptFilter = (type, filterIdx) => {
    let temp = []
    for (let el of this[type]) {
      if (el.checked) temp.push(el.value)
    }
    this.closeFilter(filterIdx)
    this.acceptFilters(temp, type)
  }

  beforeUploads = (file) => {
    this.uploads.push(file)
    this.changeCommentInput()
    return false
  }

  onRemoveUploads = (file) => {
    let tmp = this.uploads.filter((f) => {
      return f.uid !== file.uid
    })
    this.uploads = tmp
    this.changeCommentInput()

    return false
  }

  beforeUploadSplit = (file) => {
    this.splitFile = file
    return false
  }

  onRemoveSplit = () => {
    this.splitFile = undefined
  }

  handleUploadSplit = async () => {
    // let splitFile = this.activeFullRequest.splitFile
    // if (splitFile) await this.deleteFile(splitFile.id, 'split')
    const formData = new FormData()
    formData.append('file', this.splitFile)
    let requestId = this.activeFullRequest.id
    this.splitUploading = true
    axios
      .post(`/api/node/v1/file/upload/split/${requestId}`, formData)
      .then(async (_) => {
        this.splitFile = undefined
        await this.openFullRequest(requestId)
        this.openNotificationWithIcon('success', 'Saved!')
      })
      .catch((_) => {
        this.openNotificationWithIcon('error', 'Error!')
      })
      .finally((_) => {
        this.splitUploading = false
      })
  }
  correctTime = (dateString) => {
    return dayjs(dateString).format('YYYY-MM-DD HH:mm')
  }
  // предпросмотр файлов
  getFile = async (file, link) => {
    try {
      const fileObject = {
        fileId: file.id,
        type: file.type,
        filename: file.original_name,
        progress: 0,
        status: false,
        data: [],
      }
      this.downloadFileProgres.push(Object.assign({}, fileObject))
      const data = await axios.get(`${link}${file.id}`, {
        responseType: 'blob',
        params: { id: file.id },
        onDownloadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded
          const total = progressEvent.total
          const progressPercentage = Math.round((loaded / total) * 100)
          this.load = progressPercentage
          const fileIndex = this.downloadFileProgres.findIndex(
            (el) => el.fileId === file.id
          )

          if (fileIndex !== -1) {
            this.downloadFileProgres[fileIndex].progress = progressPercentage
            this.downloadFileProgres[fileIndex].status = true
          }
          this.downloadFileProgres = [...this.downloadFileProgres]
        },
      })
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].data = data
        this.downloadFileProgres[fileIndex].status = false
      }
      return data
    } catch (error) {
      console.error(`Error downloading file`)
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].status = 'exception'
      }
      this.downloadFileProgres = [...this.downloadFileProgres]
    }
  }
  downloadPDF = async () => {
    try {
      this.disabledDownloadPDF = true
      let response = await axios.get(
        `/api/node/v1/statuses/getOneRequest/${this.activeFullRequest.id}?lang=EN&pdf`,
        {
          responseType: 'blob',
        }
      )

      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute(
        'download',
        `Request ${this.activeFullRequest.id}.pdf`
      )
      document.body.appendChild(fileLink)
      fileLink.click()
      document.body.removeChild(fileLink)
      this.disabledDownloadPDF = false
    } catch (error) {
      console.error(`Error downloading file`, error)
    }
  }
}

export default RequestsStore
