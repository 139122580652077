import { makeAutoObservable } from 'mobx'
import { notification } from 'antd'
import download from 'downloadjs'
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf.js'
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry'
import api from '../utils/axiosAuthInterceptor'
PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker
//const PDFJS = await import('pdfjs-dist/legacy/build/pdf');
const axios = api
//const PDFJS = require("pdfjs-dist/build/pdf.worker");
//const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');
//PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

class AuditStore {
  tabs = undefined
  editTab = undefined
  temp = [1, 2, 3, 4, 5, 6, 6, 7, 8, 9, 0]
  saving = false
  reaudit = false
  lastReauditDate = null
  page = 1
  pageSize = 20
  count = null
  memoLength = 0
  loaderMainPage
  isSendEmailModalOpen = false
  isMemoEmailModalOpen = false
  isMemoDownloadModalOpen = false

  isDownloadModalOpen = false
  modalToDelete = false
  workshopsColumns = []
  workshopsData = []
  oldAudit = []
  newGeneralData = []
  workshopCount = 1
  reauditCount = 0
  checkBoxValueDownload = []
  checkBoxValue = false

  checkBoxValueLanguage = false
  checkBoxMemo = false

  reauditLength = ''
  progresLoad = null
  loadDownload = null
  uploadVideo = null
  currentLoadDownload = null
  statusLoad = ''
  currentAuditId = undefined
  isAuditEdit = false
  activeAuditTab = 1
  isAuditEditList = [0, 0, 0, 0, 0, 0, 0, 0]
  data = {}
  files = []
  filters = {
    product: [],
    visitors: [],
    date: [],
    memoDate: [],
    factory: [],
    class: [],
    status: [],
    comment: [],
    statusAudit: [],
    // visitorAnalytic: [],
    // visits: null,
    // role: null,
    // dateAnalytics: [],
    // typeVisits: null,
  }
  popovers = {
    visitors: false,
    product: false,
    factory: false,
    class: false,
    status: false,
    comment: false,
    date: false,
    memoDate: false,
    statusAudit: false,
  }
  auditLoading = false
  datasetAuditGeneral = []
  auditUpdated = undefined
  auditGeneralUpdated = new Date(1970)
  auditData = {}
  auditInfo = {}
  infoLastReaudit = {}

  updateGeneralTable = []
  auditSummary = []
  auditRecommend = []
  checkListColor = []
  checkListValues = []
  auditProblemFiles = []

  generalTableColumns = []

  workShopColumns = [
    {
      dataIndex: 'basic_info',
      title: 'Workshop',
      colSpan: 2,
      width: 500,
    },
    {
      dataIndex: 'editable_info',
      title: 'Field',
      editable: true,
      colSpan: 0,
      width: 733,
    },
  ]

  firstWorkShopColumns = []

  workShopDataSource = [
    {
      key: '31',
      dataIndex: 'shop_name',
      basic_info: 'Shop name',
      editable_info: 'Injection for glue sticker',
    },
    {
      key: '32',
      dataIndex: 'workers_num',
      basic_info: 'N of workers',
      editable_info: '6',
    },
    {
      key: '33',
      dataIndex: 'installed_capacity',
      basic_info: 'Installed capacity (pcs/shift)',
      editable_info: '24h after starting, 6.5T/day(2lines)',
    },
    {
      key: '34',
      dataIndex: 'working_shift',
      basic_info: 'Working shift/day',
      editable_info: '24h, 2days rest after 10days',
    },
    {
      key: '35',
      dataIndex: 'rejection_rate',
      basic_info: 'Rejection rate',
      editable_info: '0% only happened in the beginning of machine adjustment',
    },
  ]

  auditReportDataSource = [
    {
      key: '1',
      col1: 'Supplier name: 供应商名称:',
      col2: 'Benew（JBW）',
      col3: 'QA auditors: 审核员：',
      col4: 'Liliano , Chen, Li, Kate',
    },
    {
      key: '2',
      col1: 'Location: 工厂位置：',
      col2: 'Jiande',
      col3: 'Product name: 产品名称：',
      col4: 'Glue gun & glue sticker',
    },
    {
      key: '3',
      col1: 'Supplier representatives: 工厂代表:',
      col2: 'Mr Wang',
      col3: 'Audit date: 审核日期：',
      col4: '02.03.2022',
    },
  ]

  tabNames = {
    0: 'General',
    1: 'General',
    2: 'Audit Result',
    3: 'Warehouse',
    4: 'Incoming Inspection',
    5: 'Manufacturing',
    6: 'Supplier Ability',
    7: 'Supplier Facilities',
  }
  currentUser = {}
  userId = null
  userTypeId = null

  auditResultColumns = []
  auditResultDataSource = []

  factoryList = []
  visitorList = []
  productGroupList = []

  auditsPreviewList = []
  auditStatuses = {
    0: 'INCOMPLETE',
    1: 'COMPLETE',
    2: 'RE-AUDIT',
  }
  filtersAnalytics = {
    visits: null,
    role: null,
    date: [],
    typeVisits: null,
    visitorAnalytic: [],
    typeGeography: null,
    engineer: null,

    dateGeography: [],
    city: [],
    visibleTableGeography: null,
    supplier: [],
    score: [],
    dateSupplier: [],
    cityGeography: [],
  }
  allUser = []
  usersSelectFilter = []
  auditAnalytics = []
  allEngineer = []

  EngineerGeography = []
  SupplierGeography = []
  allNameAuditsInSelect = []
  CityGeography = []
  filesMemo = []
  loadOnefile = null
  addCompetitors = false
  visibleModalAddCompetitors = false
  nameCompetiorExist = ''
  colorIndicator = {
    null: 'indicatorGray',
    0: 'indicatorRed',
    1: 'indicatorGreen',
    2: 'indicatorBlue',
    3: 'indicatorYellow',
  }
  colorTag = {
    0: 'error',
    1: 'success',
    2: 'processing',
    3: 'warning',
    4: 'default',
  }
  downloadFileProgresOldAudit = []
  downloadFileProgres = []
  //chat
  commentsLanguage = {
    chat_general: undefined,
    chat_audit_result: undefined,
    chat_warehouse: undefined,
    chat_incoming_inspection: undefined,
    chat_manufacturing: undefined,
    chat_supplier_ability: undefined,
    chat_supplier_facilities: undefined,
    chat_memo: undefined,
  }
  commentsCounts = {}
  commentsData = {
    chat_general: {},
    chat_audit_result: {},
    chat_warehouse: {},
    chat_incoming_inspection: {},
    chat_manufacturing: {},
    chat_supplier_ability: {},
    chat_supplier_facilities: {},
    chat_memo: {},
  }
  commentsUsers = {
    chat_general: [],
    chat_audit_result: [],
    chat_warehouse: [],
    chat_incoming_inspection: [],
    chat_manufacturing: [],
    chat_supplier_ability: [],
    chat_supplier_facilities: [],
    chat_memo: [],
  }
  acceptUpload =
    'image/*,video/*,.pdf,.docx,.doc,.xls,.xlsx,.xlsm,.xlsb,.xlam,application/msword,.rtf,.txt,.wps,.et,.ods,.odc'
  allowedFileTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
  ]
  constructor() {
    makeAutoObservable(this)
  }

  //получить данные юзера
  // getUser = async () => {
  //   let response = await axios.get('/api/node/v1/users/get/current')

  //   this.currentUser = response.data
  //   this.userId = response.data.userId
  //   this.userTypeId = response.data.userTypeId //post_id
  // }

  //получить данные юзера
  getUser = async () => {
    let response = await axios.get('/api/node/v1/users/get/current')

    this.currentUser = response.data
    this.userId = response.data.userId
    this.userTypeId = response.data.userTypeId //post_id
  }

  changePage = (page, pageSize) => {
    this.page = page
    this.pageSize = pageSize

    this.getAuditsPreviewList().then(() => {
      window.scrollTo(0, 0)
    })
  }
  formatDate(date_string) {
    var date = new Date(date_string)
    var date_string_format =
      date.getDate() +
      '.' +
      (date.getMonth() + 1) +
      '.' +
      date.getFullYear() +
      ' ' +
      date.getHours() +
      ':'
    var minuters = date.getMinutes()

    if (minuters.toString().length === 1) date_string_format += '0' + minuters
    else date_string_format += minuters

    return date_string_format
  }

  //получение списка фабрик для создания аудита
  getFactoryList = async (audit_id) => {
    let response = await axios.get('/api/node/v1/audits/factories', {
      params: { audit_id: audit_id },
    })
    this.factoryList = response.data.data.factories
  }
  changeCheckBoxValue = (e) => {
    this.checkBoxValue = e.target.value
    this.checkBoxValueLanguage = false
  }

  changeCheckBoxValueDownload = (e) => {
    this.checkBoxValueDownload = e
    if (!this.checkBoxValueDownload.includes('excel')) {
      this.checkBoxValueLanguage = false
    }
    if (!this.checkBoxValueDownload.includes('pdf')) {
      this.checkBoxMemo = false
    }
  }
  changeCheckBoxValueLanguage = (e) => {
    this.checkBoxValueLanguage = e.target.value
  }
  changeCheckBoxMemo = (e) => {
    this.checkBoxMemo = e.target.value
  }

  clearFilters = async () => {
    try {
      this.auditLoading = true
      this.filters = {
        product: [],
        visitors: [],
        date: [],
        factory: [],
        class: [],
        status: [],
        comment: [],
        memoDate: [],
        statusAudit: [],
      }
      this.popovers = {
        visitors: false,
        product: false,
        factory: false,
        class: false,
      }
      this.page = 1

      this.auditLoading = false

      this.getAuditsPreviewList()
    } catch (error) {}
  }

  //получение списка аудитов для вывода на глаdata?.productGroupsвной странице раздела
  getAuditsPreviewList = async (audit_id) => {
    try {
      this.loaderMainPage = false

      let response = await axios.get('/api/node/v1/audits', {
        params: {
          page: this.page,
          size: this.pageSize,
          factory: this.filters.factory.join(),
          users: this.filters.visitors.join(),
          product: this.filters.product.join(),
          class: this.filters.class,
          status: this.filters.status,
          // comment: this.filters.comment,
          statusAudit: this.filters.statusAudit,
          date: this.filters.date.join(),
          memoDate: this.filters.memoDate.join(),
          audit_id,
        },
      })
      this.commentsCounts = {}
      this.commentsData = {
        chat_general: {},
        chat_audit_result: {},
        chat_warehouse: {},
        chat_incoming_inspection: {},
        chat_manufacturing: {},
        chat_supplier_ability: {},
        chat_supplier_facilities: {},
        chat_memo: {},
      }
      this.commentsUsers = {
        chat_general: [],
        chat_audit_result: [],
        chat_warehouse: [],
        chat_incoming_inspection: [],
        chat_manufacturing: [],
        chat_supplier_ability: [],
        chat_supplier_facilities: [],
        chat_memo: [],
      }
      this.count = response.data.data.count
      this.auditsPreviewList = response.data.data.data
      this.data.users = response.data.data.allUsers

      this.data.productGroups = response.data.data.productGroups
      this.getFactoryList()
      this.clearFiltersAnalytics()
      this.loaderMainPage = true
    } catch (error) {
      console.log(error, 'err')
    }
  }
  selectVisitors = (e, setOpen) => {
    setOpen((prev) => ({ ...prev, user: false }))
    this.filters.visitors = e
    this.loaderMainPage = true
  }

  selectDate = async (e) => {
    if (e === null) {
      this.filters.date = []
    } else {
      this.filters.date = e.map((el, i) =>
        i === 0
          ? `${el.toLocaleString().slice(4, 16)} 00:00:00`
          : `${el.toLocaleString().slice(4, 16)} 23:00:00`
      )
    }
    this.loaderMainPage = true
    this.auditLoading = false
  }
  selectMemoDate = async (e, setDate) => {
    if (e === null || undefined) {
      this.filters.memoDate = []
    } else {
      this.filters.memoDate = e.map((el, i) =>
        i === 0
          ? `${el.toLocaleString().slice(4, 16)} 00:00:00`
          : `${el.toLocaleString().slice(4, 16)} 23:00:00`
      )
    }
    this.loaderMainPage = true
    this.auditLoading = false
  }
  selectFactory = (e, setOpen) => {
    setOpen((prev) => ({ ...prev, factory: false }))
    this.filters.factory = e
    this.loaderMainPage = true
  }
  selectClass = (e) => {
    this.filters.class = e !== undefined ? e : 0
    this.loaderMainPage = true
  }
  selectStatus = (e) => {
    this.filters.status = e !== undefined ? e : null
    this.loaderMainPage = true
  }
  selectStatusAudit = (e) => {
    this.filters.statusAudit = e !== undefined ? e : null
    this.loaderMainPage = true
  }
  selectProduct = (e, setOpen) => {
    setOpen((prev) => ({ ...prev, product: false }))
    this.filters.product = e
    this.loaderMainPage = true
  }

  unSelectProduct = (category) => {
    this.filters[category] = []
    this.popovers[category] = false
    this.page = 1
    this.getAuditsPreviewList()
  }
  popoversOpen = (category) => {
    const categories = {
      product: false,
      factory: false,
      visitors: false,
      class: false,
      status: false,
      comment: false,
      // memoDate: false
    }
    categories[category] = true
    this.popovers = categories
  }
  visitorsOpen = () => {
    this.popovers.visitors = true
    this.popovers.product = false
    this.popovers.factory = false
  }
  popoversClose = (category) => {
    this.page = 1
    this.getAuditsPreviewList()
    this.popovers[`${category}`] = false
  }
  reAudits = async (id, markerWord) => {
    this.reaudit = true
    await axios.put(`/api/node/v1/audits/${id}`)
    await this.getAudit(id)
    this.getAuditsPreviewList()
    // this.auditInfo.infoStatus.approve = null
  }
  endReAudit = async () => {
    this.auditLoading = true
    this.reaudit = false
    const calculateCategoryScore = (categoryData) => {
      return categoryData.reduce(
        (partSum, partItem) => partSum + (partItem.value?.score || 0),
        0
      )
    }
    const categoryKeys = [
      'Warehouse',
      'Incoming Inspection',
      'Manufacturing',
      'Supplier Ability',
      'Supplier Facilities',
    ]

    const body = categoryKeys.map((categoryKey, index) => {
      const audit_tab_id = index + 3
      const categoryData = this.auditData[categoryKey]
      const score = calculateCategoryScore(categoryData)
      return { audit_tab_id, audit_id: +this.currentAuditId, score, remark: '' }
    })

    body.push({
      audit_tab_id: 8,
      audit_id: +this.currentAuditId,
      score: body.reduce((partSum, partItem) => partSum + partItem.score, 0),
      remark: '',
      status: this.auditInfo.infoStatus?.approve,
    })
    await axios.post(`/api/node/v1/audits/saveDetails`, body)
    this.auditLoading = false
  }
  // создание воркшопа
  createWorkshop = async () => {
    const id = +this.currentAuditId
    let response = await axios.post('/api/node/v1/audits/workshop/' + id)
    const newColumn = [
      {
        dataIndex: 'name',
        title: response.data.data.title,
        colSpan: 2,
        width: '40%',
        workshop_id: response.data.data.workshop_id,
      },
      {
        dataIndex: 'value',
        title: 'Field',
        editable: true,
        colSpan: 0,
        width: '60%',
        workshop_id: response.data.data.workshop_id,
      },
    ]
    return {
      workshopsColumns: newColumn,
      workshopsData: response.data.data.newWorkShop,
    }
  }
  removeWorkshop = async (id) => {
    const body = { workshop_id: id, audit_id: +this.currentAuditId }
    await axios.post('/api/node/v1/audits/removeWorkshop', body)
  }
  //сохранение воркошопов
  saveWorkshop = async (workshops) => {
    let filesToDelete
    filesToDelete = workshops
      .filter((el) => el.value?.length && el.audit_field_id === 33)
      .map((uploads) =>
        uploads.value
          .filter((element) => element.id)
          .map((upload) => +upload.id)
      )
      .flat()

    if (!filesToDelete.length) {
      filesToDelete = [
        workshops.filter((el) => el.audit_field_id === 33)[0].workshop_id,
      ]
    }

    await axios.post(
      `/api/node/v1/audits/deleteWorkshopsFiles/${this.currentAuditId}`,
      filesToDelete
    )
    const files = workshops
      .filter((el) => el.value?.length && el.audit_field_id === 33)
      .map((uploads) =>
        uploads.value
          .filter(
            (element) =>
              !element.id &&
              (element.type.includes('image') ||
                element.name.split('.').pop().toLowerCase() === 'heic')
          )
          .map((upload) => ({
            ...upload,
            audit_id: uploads.audit_id,
            audit_field_id: uploads.audit_field_id,
            workshop_id: uploads.workshop_id,
          }))
      )
      .flat()

    if (
      workshops
        .filter((el) => el.value?.length && el.audit_field_id === 33)
        .map((uploads) =>
          uploads.value
            .filter(
              (element) =>
                !element.id &&
                !element.type.includes('image') &&
                element.name.split('.').pop().toLowerCase() !== 'heic' // Изменение здесь
            )
            .map((upload) => ({
              ...upload,
              audit_id: uploads.audit_id,
              audit_field_id: uploads.audit_field_id,
              workshop_id: uploads.workshop_id,
            }))
        )
        .flat().length
    ) {
      this.openNotificationWithIcon(
        'error',
        'This file format is not available'
      )
    }
    if (files && files.length) {
      const auditBody = {
        audit_id: files.map((el) => el.audit_id),
        audit_field_id: files.map((el) => el.audit_field_id),
        workshop_id: files.map((el) => el.workshop_id),
      }
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        formData.append(`uploads${i}`, files[i].originFileObj)
      }
      await axios.post('/api/node/v1/audits/saveWorkshopsFiles', formData, {
        params: auditBody,
      })
    }
    const values = workshops.filter((el) => el.audit_field_id !== 33)
    await axios.post('/api/node/v1/audits/saveWorkshops', values)
  }
  //Получение информации вкладки audit Result
  getLoadingAuditResult = async (audit_id, t, auditDataGeneral) => {
    try {
      let response = await axios.get(
        `/api/node/v1/audits/auditresult/${audit_id}`
      )

      this.newGeneralData = response.data.data.data.newGeneralInfo
      this.reauditCount = response.data.data.data.reauditCount
      this.lastReauditDate = response.data.data.data?.lastReauditDate
      this.workshopsData = response.data.data.workshopsData
      this.oldAudit = response.data.data.data.oldAudit
      this.auditProblemFiles = response.data.data.data.auditProblemFiles
      this.reauditLength = response.data.data.reauditLength
      this.auditData = response.data.data.data.auditData
      this.auditInfo = response.data.data.data.audit
      this.auditInfo.infoStatus = response.data.data.data.infoStatus
      this.infoLastReaudit = response.data.data.data.lastReaudit

      const thisAudit = this.auditsPreviewList.filter(
        (el) => +el.id === +audit_id
      )
      const fullNameFabric = this.factoryList.filter(
        (el) => el.name === thisAudit[0]['factory.abbreviation']
      )
      this.auditReportDataSource = [
        {
          key: '1',
          col1: 'Supplier name: 供应商名称:',
          col2: thisAudit[0].factory.name,
          col3: 'QA auditors: 审核员：',
          col4: this.newGeneralData?.dataColumns

            .find((el) => el.name === 'Who visited 审核员')
            [`value${this.reauditLength - 1}`]?.map((el) => {
              return `${el.name} ${el.surname}`
            })

            ?.join(', '),
        },
        {
          key: '2',
          col1: 'Location: 工厂位置：',
          col2:
            thisAudit[0] &&
            `${
              fullNameFabric[0]?.province ? fullNameFabric[0]?.province : ''
            } ${fullNameFabric[0]?.city ? fullNameFabric[0]?.city : ''} ${
              fullNameFabric[0]?.address ? fullNameFabric[0]?.address : ''
            }`,
          col3: 'Product name: 产品名称：',
          col4: auditDataGeneral
            ?.filter(
              (el) =>
                el.name === 'Products already supplied to us 已经供货的产品' ||
                el.name ===
                  'Other products not supplied to us 其他可能合作的产品'
            )
            ?.map((el) => el.value?.split(',')?.map((el) => t(el)?.trim()))
            ?.flat()
            .filter((el) => el)
            .join(', '),
        },
        {
          key: '3',
          col1: 'Supplier representatives: 工厂代表:',
          col2: this.newGeneralData?.dataColumns.find(
            (el) => el.name === 'Who accompanied 陪同人员'
          )[`value${this.reauditLength - 1}`],
          col3: 'Audit date: 审核日期：',
          col4: this.lastReauditDate
            ? new Date(this.lastReauditDate).toLocaleDateString()
            : new Date(thisAudit[0]?.created_at).toLocaleDateString(),
        },
      ]
    } catch (error) {
      console.log(error, 'err')
    }
  }
  //Получение информации чек листов аудита
  getLoadingAudutCheckist = async (audit_id, t) => {
    try {
      let response = await axios.get(
        `/api/node/v1/audits/checklist/${audit_id}`
      )
      this.newGeneralData = response.data.data.data.newGeneralInfo
      this.reauditCount = response.data.data.data.reauditCount
      this.lastReauditDate = response.data.data.data?.lastReauditDate
      this.workshopsData = response.data.data.workshopsData
      this.oldAudit = response.data.data.data.oldAudit
      this.auditProblemFiles = response.data.data.data.auditProblemFiles
      this.reauditLength = response.data.data.reauditLength
      this.auditData = response.data.data.data.auditData
      this.auditInfo = response.data.data.data.audit
      this.auditInfo.infoStatus = response.data.data.data.infoStatus
      this.infoLastReaudit = response.data.data.data.lastReaudit
    } catch (error) {
      console.log(error, 'err')
    }
  }
  //Получение информации вкладки audit General
  getAuditGeneral = async (audit_id, t) => {
    try {
      if (!this.auditsPreviewList.length) {
        await this.getAuditsPreviewList(audit_id)
      }

      let response = await axios.get(
        `/api/node/v1/audits/auditgeneral/${audit_id}`
      )
      this.newGeneralData = response.data.data.data.newGeneralInfo
      this.reauditCount = response.data.data.data.reauditCount
      this.lastReauditDate = response.data.data.data?.lastReauditDate
      this.workshopsData = response.data.data.workshopsData
      this.oldAudit = response.data.data.data.oldAudit
      this.auditProblemFiles = response.data.data.data.auditProblemFiles

      this.workshopsColumns = response.data.data.workshopsColumn.map((el) => [
        {
          dataIndex: 'name',
          title: el.title,
          colSpan: 2,
          width: '40%',
          workshop_id: el.workshop_id,
        },
        {
          dataIndex: 'value',
          title: 'Field',
          editable: true,
          colSpan: 0,
          width: '60%',
          workshop_id: el.workshop_id,
        },
      ])
      if (response.data.data.reaudit === 2) {
        this.reaudit = true
      } else {
        this.reaudit = false
      }
      this.reauditLength = response.data.data.reauditLength
      this.auditData = response.data.data.data.auditData
      this.auditInfo = response.data.data.data.audit
      this.auditInfo.infoStatus = response.data.data.data.infoStatus
      this.infoLastReaudit = response.data.data.data.lastReaudit
      if (response.data.data.files && response.data.data.files.length) {
        this.files = response.data.data.files
      } else {
        this.files = [1]
      }

      if (response.data.data.data.auditData['General']) {
        this.generalTableColumns = response.data.data.data.auditData['General']
          .filter(
            (obj) =>
              obj.name === 'Factory code 工厂代号' ||
              // obj.name === 'Average salary for workers工人平均工资' ||
              obj.name === 'N of direct people一线工人人数' ||
              obj.name === 'Products already supplied to us 已经供货的产品' ||
              obj.name === 'Brands for Russian Market 俄罗斯市场的品牌'
          )
          .map((el) => [
            {
              dataIndex: 'name',
              title: el.group,
              colSpan: el.group === 'Basic information 基本信息' ? 0 : 2,
              width: '40%',
            },
            {
              dataIndex: 'value',
              title: 'Field',
              editable: true,
              colSpan: 0,
              width: '60%',
            },
          ])
      }
    } catch (error) {
      console.log(error, 'err')
    }
  }
  //получение информации для просмотра/редактирования Memo
  getLoadingMemo = async (audit_id, t) => {
    try {
      let response = await axios.get(`/api/node/v1/audits/memo/${audit_id}`)
      this.newGeneralData = response.data.data.data.newGeneralInfo

      this.reauditCount = response.data.data.data.reauditCount
      this.lastReauditDate = response.data.data.data?.lastReauditDate
      this.workshopsData = response.data.data.workshopsData
      this.oldAudit = response.data.data.data.oldAudit
      this.auditProblemFiles = response.data.data.data.auditProblemFiles
      this.reauditLength = response.data.data.reauditLength
      this.auditData = response.data.data.data.auditData
      this.auditInfo = response.data.data.data.audit
      this.auditInfo.infoStatus = response.data.data.data.infoStatus
      this.infoLastReaudit = response.data.data.data.lastReaudit
    } catch (error) {
      console.log(error, 'err')
    }
  }
  //получение информации для просмотра/редактирования аудита
  getAudit = async (audit_id, t) => {
    try {
      if (!this.auditsPreviewList.length) {
        await this.getAuditsPreviewList(audit_id)
      }

      if (!this.data?.brands?.length && !this.data?.competitors?.length)
        await this.getBrandsAndCompetitors('allAudits', audit_id)
      let response = await axios.get(`/api/node/v1/audits/${audit_id}`)
      this.newGeneralData = response.data.data.data.newGeneralInfo
      this.reauditCount = response.data.data.data.reauditCount
      this.lastReauditDate = response.data.data.data?.lastReauditDate
      this.workshopsData = response.data.data.workshopsData
      this.oldAudit = response.data.data.data.oldAudit

      this.auditProblemFiles = response.data.data.data.auditProblemFiles
      this.workshopsColumns = response.data.data.workshopsColumn.map((el) => [
        {
          dataIndex: 'name',
          title: el.title,
          colSpan: 2,
          width: '40%',
          workshop_id: el.workshop_id,
        },
        {
          dataIndex: 'value',
          title: 'Field',
          editable: true,
          colSpan: 0,
          width: '60%',
          workshop_id: el.workshop_id,
        },
      ])

      if (response.data.data.reaudit === 2) {
        this.reaudit = true
      } else {
        this.reaudit = false
      }
      this.reauditLength = response.data.data.reauditLength
      this.auditData = response.data.data.data.auditData
      this.auditInfo = response.data.data.data.audit
      this.auditInfo.infoStatus = response.data.data.data.infoStatus
      this.infoLastReaudit = response.data.data.data.lastReaudit
      if (response.data.data.files && response.data.data.files.length) {
        this.files = response.data.data.files
      } else {
        this.files = [1]
      }

      if (response.data.data.data.auditData['General']) {
        this.generalTableColumns = response.data.data.data.auditData['General']
          .filter(
            (obj) =>
              obj.name === 'Factory code 工厂代号' ||
              // obj.name === 'Average salary for workers工人平均工资' ||
              obj.name === 'N of direct people一线工人人数' ||
              obj.name === 'Products already supplied to us 已经供货的产品' ||
              obj.name === 'Brands for Russian Market 俄罗斯市场的品牌'
          )
          .map((el) => [
            {
              dataIndex: 'name',
              title: el.group,
              colSpan: el.group === 'Basic information 基本信息' ? 0 : 2,
              width: '40%',
            },
            {
              dataIndex: 'value',
              title: 'Field',
              editable: true,
              colSpan: 0,
              width: '60%',
            },
          ])

        const thisAudit = this.auditsPreviewList.filter(
          (el) => +el.id === +audit_id
        )
        const fullNameFabric = this.factoryList.filter(
          (el) => el.name === thisAudit[0]['factory.abbreviation']
        )
        this.auditReportDataSource = [
          {
            key: '1',
            col1: 'Supplier name: 供应商名称:',
            col2: thisAudit[0].factory.name,
            col3: 'QA auditors: 审核员：',
            col4: this.newGeneralData?.dataColumns
              .find((el) => el.name === 'Who visited 审核员')
              [`value${this.reauditLength - 1}`]?.map((el) => {
                return `${el.name} ${el.surname}`
              })
              ?.join(', '),
          },
          {
            key: '2',
            col1: 'Location: 工厂位置：',
            col2:
              thisAudit[0] &&
              `${
                fullNameFabric[0]?.province ? fullNameFabric[0]?.province : ''
              } ${fullNameFabric[0]?.city ? fullNameFabric[0]?.city : ''} ${
                fullNameFabric[0]?.address ? fullNameFabric[0]?.address : ''
              }`,
            col3: 'Product name: 产品名称：',
            col4: this.auditData['General']
              ?.filter(
                (el) =>
                  el.name ===
                    'Products already supplied to us 已经供货的产品' ||
                  el.name ===
                    'Other products not supplied to us 其他可能合作的产品'
              )
              ?.map((el) => el.value?.split(',')?.map((el) => t(el)?.trim()))
              ?.flat()
              .filter((el) => el)
              .join(', '),
          },
          {
            key: '3',
            col1: 'Supplier representatives: 工厂代表:',
            col2: this.newGeneralData?.dataColumns.find(
              (el) => el.name === 'Who accompanied 陪同人员'
            )[`value${this.reauditLength - 1}`],
            col3: 'Audit date: 审核日期：',
            col4: this.lastReauditDate
              ? new Date(this.lastReauditDate).toLocaleDateString()
              : new Date(thisAudit[0]?.created_at).toLocaleDateString(),
          },
        ]
      }
    } catch (error) {
      console.log(error, 'err')
    }
  }

  getFilters = async (allAudits, audit_id, key) => {
    let response = await axios.get('/api/node/v1/audits/filters', {
      params: { listAudits: allAudits, audit_id, tab: key },
    })
    const productGroups = response.data.data.productGroups
    const users = response.data.data.users
    const productGroupsAudit = response.data.data.productGroupsAudit
    this.data.productGroups = productGroups
    this.data.users = users
    this.data.productGroupsAudit = productGroupsAudit
  }
  getBrandsAndCompetitors = async (allAudits, audit_id) => {
    await this.getFilters('allAudits', audit_id)
    let response = await axios.get('/api/node/v1/audits/brands')
    const brands = response.data.data.brands
    const competitors = response.data.data.competitors
    this.data.brands = brands
    this.data.competitors = competitors
  }
  changeActiveTab = (e) => {
    this.activeAuditTab = e
  }

  setCurrentAuditId = (id) => {
    this.currentAuditId = +id
  }

  setEdit = (isEdit) => {
    this.isAuditEdit = isEdit
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
      style: { zIndex: 10001 },
    })
  }
  removePhoto = async (id) => {
    try {
      await axios.delete(`/api/node/v1/audits/${id}`)
    } catch (error) {
      console.log(error, 'err')
    }
  }

  removeMemoFile = async (file_id) => {
    try {
      await axios.delete(`/api/node/v1/audits/memo/${file_id}`)
    } catch (error) {}
  }
  setAuditResult = (
    problems,
    recommend,
    scoreDetails,
    summary,
    auditComment
  ) => {
    if (problems !== null) this.auditData['Audit Result'].problems = problems
    if (recommend !== null) {
      this.auditData['Audit Result'].recommend = recommend
    }
    if (scoreDetails !== null)
      this.auditData['Audit Result'].scoreDetails = scoreDetails
    if (summary !== null) this.auditData['Audit Result'].summary = summary
    if (auditComment !== null)
      this.auditData['Audit Result'].auditComment = auditComment
  }

  saveAuditResultData = async (problemFiles) => {
    try {
      this.saving = true
      this.auditData['Audit Result'].summary = this.auditData[
        'Audit Result'
      ].summary.filter((el) => el.action || el.productGroups || el.unconformity)
      this.auditData['Audit Result'].recommend = this.auditData[
        'Audit Result'
      ].recommend.filter(
        (el) => el.action || el.productGroups || el.recommendations
      )
      if (problemFiles) await this.saveProblemFiles(problemFiles)
      let body = {
        audit_id: this.currentAuditId,
        audit_tab_name: 'Audit Result',
        data: this.auditData['Audit Result'],
      }
      this.auditLoading = true

      const saveData = await axios.put(
        '/api/node/v1/audits/check_list_values',
        body
      )
      const idAudit = this.auditInfo.id
      if (saveData) {
        // await this.getAudit(idAudit)
        let approveStatus = null
        let action = false
        let implementation_date = false
        let accomplished = false
        const idLastReaudit = this.infoLastReaudit.id
        const data = body?.data?.summary

        data.forEach((obj) => {
          if (obj.action === null || obj.action === '') {
            action = true
          }
          if (obj.implementation_date === null) {
            implementation_date = true
          }
          if (obj.accomplished === false) {
            accomplished = true
          }
        })

        if (this.auditInfo.infoStatus?.approve) {
          if (!data.length) {
            approveStatus = 1
          }
          if (data.length && !action && !implementation_date && !accomplished) {
            approveStatus = 2
          }
          if (data.length && (action || implementation_date || accomplished)) {
            approveStatus = 3
          }
        } else {
          approveStatus = null
        }

        let status = await axios.post(`/api/node/v1/audits/statusAudit`, {
          params: {
            idLastReaudit,
            idAudit,
            approveStatus,
            action,
            implementation_date,
            accomplished,
          },
        })
        await this.getAudit(idAudit)
      }

      this.auditLoading = false

      this.saving = false
    } catch (error) {
      console.log(error, 'err')
    }
  }
  saveProblemFiles = async (problemFileList) => {
    const oldFilesId = problemFileList
      .filter((file) => file.id)
      .map((file) => file.id)
    await axios.delete(
      `/api/node/v1/audits/problemFiles/${this.currentAuditId}`,
      { params: { id: oldFilesId } }
    )
    const filesAuditId = {
      audit_id: this.currentAuditId,
    }

    const newFiles = problemFileList.filter(
      (file) => !file.id && file.type.includes('image')
    )
    const allNewFiles = problemFileList.filter((file) => !file.id)

    if (newFiles.length !== allNewFiles.length) {
      this.openNotificationWithIcon(
        'error',
        'This file format is not available'
      )
    }
    const formData = new FormData()
    for (let i = 0; i < newFiles.length; i++) {
      formData.append(`uploads${i}`, newFiles[i].originFileObj)
    }
    await axios.post('/api/node/v1/audits/problemFiles', formData, {
      params: filesAuditId,
    })
  }
  saveData = async (data) => {
    await axios.put(
      '/api/node/v1/audits/reauditData',
      this.newGeneralData.dataColumns
    )
    function contains(where, what) {
      for (var i = 0; i < what.length; i++) {
        if (where.indexOf(what[i]) === -1) return false
      }
      return true
    }
    try {
      this.saving = true
      if (
        +this.activeAuditTab === 1 &&
        data.filter(
          (el) =>
            (el.key === 10 && !el.value?.trim()) ||
            (el.key === 9 && !el.value?.trim()) ||
            (el.key === 8 && !el.value?.trim()) ||
            (el.key === 7 && !el.value?.trim()) ||
            (el.key === 3 && !el.value?.trim())
        ).length
      ) {
        this.openNotificationWithIcon(
          'warning',
          'Not all the necessary data has been filled in'
        )
      }
      if (+this.activeAuditTab === 1 && data !== this.auditData['General']) {
        let body = {
          audit_id: this.currentAuditId,
          data: [],
          factoryId: this.auditInfo.factory_id,
          suppliersName: this.auditInfo?.factory?.abbreviation,
        }
        for (const line of data) {
          body.data.push({
            id: line.id ? +line.id : '',
            audit_id: +this.currentAuditId,
            audit_field_id: +line.key,
            value: Array.isArray(line.value)
              ? line.value.map((el) => (el.id ? el.id : el))?.join()
              : line.value,
          })
        }
        await axios.put('/api/node/v1/audits/audit_field_values', body)
      } else if (
        +this.activeAuditTab !== 1 &&
        +this.activeAuditTab !== 2 &&
        +this.activeAuditTab !== 8
      ) {
        let body = {
          audit_id: this.currentAuditId,
          data: [],
          supplierAb: {},
        }
        let fileToDelete = []
        const arrToNotification = []
        const checkListArr = this.auditData[this.tabNames[this.activeAuditTab]]
        checkListArr.forEach((line) => {
          // Проверяем с какого чек листа пришло изменение (2-7)
          const isAuditTabSix = line.audit_tab_id === 6
          // Если чеклист = 6
          if (
            (!isAuditTabSix &&
              line?.value?.score <= 3 &&
              !line?.value?.remark &&
              line.color) ||
            (!isAuditTabSix &&
              line?.value?.score <= 0 &&
              !line?.value?.remark &&
              !line.color) ||
            (!isAuditTabSix && !line.value)
          ) {
            arrToNotification.push('1')
          } else if (isAuditTabSix) {
            // Если чеклист != 6
            const isCheckboxFirst = line?.id === 25 && line?.value?.checkbox
            const isCheckboxSecond = line?.id === 26 && line?.value?.checkbox
            const isValueScoreThree =
              line?.value?.score <= 3 && !line?.value?.remark && line.color
            const isValueScoreZero =
              line?.value?.score <= 0 && !line?.value?.remark && !line.color

            if (
              (isCheckboxFirst && isValueScoreThree) ||
              (isCheckboxSecond && isValueScoreThree) ||
              (!isCheckboxFirst && !isCheckboxSecond && isValueScoreZero) ||
              (isValueScoreThree && line?.id !== 25 && line?.id !== 26)
            ) {
              arrToNotification.push('1')
            }
          }
          body.data.push({
            id: line?.value?.id,
            audit_check_list_id: line?.id,
            score: line?.value?.score ?? null,
            comment: line?.value?.comment,
            checkbox: line?.value?.checkbox,
            remark: line?.value?.remark,
          })
        })
        if (arrToNotification.length) {
          this.openNotificationWithIcon(
            'error',
            'The remark field was not filled in'
          )
        }

        const supplier = this.auditData[this.tabNames[this.activeAuditTab]]
        if (
          +this.activeAuditTab === 6 &&
          supplier[0].value.checkbox &&
          supplier[1].value.checkbox === null
        ) {
          body.supplierAb = supplier[1]
          fileToDelete = supplier[1].files.map((el) => +el.id)
        } else if (
          +this.activeAuditTab === 6 &&
          supplier[0].value.checkbox === null &&
          supplier[1].value.checkbox
        ) {
          body.supplierAb = supplier[0]
          fileToDelete = supplier[0]?.files?.map((el) => +el.id)
        }

        if (fileToDelete.length) {
          await axios.delete(
            `/api/node/v1/audits/checklist/${fileToDelete[0]}`,
            { params: { id: fileToDelete } }
          )
        }
        let response = await axios.put(
          '/api/node/v1/audits/check_list_values',
          body
        )
        if (response.data.success) {
          const categoryKeys = [
            'Warehouse',
            'Incoming Inspection',
            'Manufacturing',
            'Supplier Ability',
            'Supplier Facilities',
          ]
          const calculateCategoryScore = (categoryData) => {
            return categoryData.reduce(
              (partSum, partItem) => partSum + (partItem.value?.score || 0),
              0
            )
          }
          const bodyDetail = categoryKeys.map((categoryKey, index) => {
            const audit_tab_id = index + 3
            const categoryData = this.auditData[categoryKey]
            const score = calculateCategoryScore(categoryData)
            return {
              audit_tab_id,
              audit_id: +this.currentAuditId,
              score,
              remark: '',
            }
          })

          const totalScore = bodyDetail.reduce(
            (partSum, partItem) => partSum + partItem.score,
            0
          )
          bodyDetail.push({
            audit_tab_id: 8,
            audit_id: +this.currentAuditId,
            score: totalScore,
            remark: '',
          })
          response = await axios.put(
            `/api/node/v1/audits/updateScoreDetails/${this.currentAuditId}`,
            bodyDetail
          )
          this.saving = false
        }
      }

      this.saving = false
    } catch (error) {
      console.log(error, 'err')
    }
  }

  createAudit = async (factoryId, createdName, fabricName, fabricCode) => {
    this.auditLoading = true
    const response = await axios.post('/api/node/v1/audits', {
      factory_id: factoryId,
      status: 0,
    })
    if (response.data.success) {
      this.getAuditsPreviewList()
      this.sendAnnounsement(
        fabricName,
        createdName,
        'createAudit',
        +response.data.id,
        fabricCode
      )
      this.auditLoading = false
      window.location.href = `/react/audits/${response.data.id}`
    } else {
      this.openNotificationWithIcon('error', response.data.message)
      this.filters.factory = [+factoryId]
      this.getAuditsPreviewList()
      this.auditLoading = false
    }
  }
  sendAnnounsement = async (fabricName, createdName, end, id, fabricCode) => {
    const body = {
      fabricName,
      createdName,
      end,
      audit_id: id,
      fabricCode,
    }
    await axios.post('/api/node/v1/audits/sendAnnounsement', body)
  }
  addWorkshop = () => {
    this.workshopCount += 1
  }

  editAuditTab = () => {
    switch (this.activeAuditTab) {
      case 0:
        let temp = [...this.datasetAuditGeneral]
        temp.splice(-1, 0, { key: 'add' })
        this.datasetAuditGeneral = [...temp]
        break
      default:
    }
    this.isAuditEdit = true
    this.isAuditEditList[this.activeAuditTab] = 1
  }

  saveAuditGeneral = async () => {
    let temp = [...this.datasetAuditGeneral]
    this.auditGeneralUpdated = new Date()
    temp.splice(-7, 7)
  }
  openSendEmailModal = () => {
    this.isSendEmailModalOpen = true
  }
  openDownloadModal = () => {
    this.isDownloadModalOpen = true
  }
  openSendMemoEmailModal = () => {
    this.isMemoEmailModalOpen = true
  }
  openSendMemoDownloadModal = () => {
    this.isMemoDownloadModalOpen = true
  }
  sendExelEmail = async (
    { email },
    thisAudit,
    summaryProduct,
    recommendProduct,
    markerWord,
    dataKey
  ) => {
    const body = {
      email: email ? email : '',
      id: +this.currentAuditId,
      factoryInf: thisAudit[0],
      summaryProduct,
      recommendProduct,
      markerWord,
      dataKey,
      store: this.auditData['Audit Result'],
      language: this.checkBoxValueLanguage,
    }
    this.auditLoading = true
    this.isDownloadModalOpen = false
    const response = await axios.post(
      '/api/node/v1/audits/sendExelMail',
      body,
      {
        responseType: 'blob',
      }
    )
    const blob = await response.data
    if (markerWord) {
      download(
        blob,
        `Unconformities and Recommendations for the factory ${thisAudit[0]['factory.abbreviation']} ${thisAudit[0].created_at}.xlsx`
      )
    }
    if (response.data.success || response.statusText) {
      this.isSendEmailModalOpen = false
      this.isMemoEmailModalOpen = false
      this.auditLoading = false
    }
  }
  sendDetailsEmail = async (
    data,
    store,
    count,
    thisAudit,
    product,
    values,
    number,
    memoName,
    dataKey,
    memoFiles
  ) => {
    const body = {
      base64: data,
      product: product.map((el) => (el?.text ? el.text : el))[0],
      date: new Date().toLocaleDateString(),
      email: Array.isArray(values.email)
        ? values.email.join(', ')
        : values.email,
      name: thisAudit[0].factory.name,
      abbreviation: thisAudit[0]['factory.abbreviation'],
      created_at: new Date(thisAudit[0].created_at).toLocaleDateString(),
      number,
      memoName,
      dataKey,
      memoFiles,
    }

    this.isSendEmailModalOpen = false
    this.isMemoEmailModalOpen = false
    this.isDownloadModalOpen = false
    await axios.post('/api/node/v1/audits/sendMail', body)

    this.auditLoading = false
  }
  saveGeneralComments = async (body) => {
    try {
      await axios.post(`/api/node/v1/audits/comment/${body.id}`, body)
      let response = await axios.get(
        '/api/node/v1/audits/' + this.currentAuditId
      )
      this.files = response.data.data.files
    } catch (error) {
      console.log(error, 'err')
    }
  }

  removeAudit = async (id) => {
    try {
      const x = await axios.delete(`/api/node/v1/audits/delete/${id}`)
      if (x.statusText) {
        this.getAuditsPreviewList()
      }
      this.modalToDelete = false
    } catch (error) {}
  }
  redirectToPage = () => {
    window.location.href = '/login'
  }
  createSMV = async () => {
    try {
      const id = this.currentAuditId
      const response = await axios.post(`/api/node/v1/audits/smv/${+id}`)
      this.auditData['Memo'][0] = response.data.data
      this.memoLength = this.memoLength + 1
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message
        this.openNotificationWithIcon('error', errorMessage)
        setTimeout(this.redirectToPage, 1500)
      } else {
        this.openNotificationWithIcon('error', error.message)
        setTimeout(this.redirectToPage, 1500)
      }
    }
  }
  removeSmv = async (id) => {
    try {
      await axios.delete(`/api/node/v1/audits/smv/${id}`)
      this.auditData['Memo'][0] = this.auditData['Memo'][0].filter(
        (el) => +el.id !== +id
      )
      this.memoLength = this.memoLength - 1
    } catch (error) {
      console.log(error, 'err')
    }
  }
  removeCheckListFiles = async (id) => {
    try {
      await axios.delete(`/api/node/v1/audits/checklist/${id}`)
    } catch (error) {}
  }
  saveMemoValues = async (body) => {
    try {
      await axios.put('/api/node/v1/audits/memo_values', body)
      return { ok: 'ok' }
    } catch (error) {
      console.log(error, 'err')
    }
  }
  saveNewCompetitors = async (body) => {
    const response = await axios.put(
      '/api/node/v1/audits/newCompetitors/save',
      body
    )

    this.data.competitors = response.data.data.competitors
  }
  saveGeneralFiles = async (body, auditFiles, pdf) => {
    try {
      this.auditLoading = true
      if (body.id && body.type) {
        await axios.post(
          `/api/node/v1/audits/upload/audits/${body.id}`,
          auditFiles,
          {
            params: {
              id: body.id,
              comment: body.comment,
              type: body.type,
              pdfCheck: pdf,
            },
          }
        )
      }
    } catch (error) {
      console.log(error, 'err')
    }
  }
  onSave = async (uploads, setFiles) => {
    this.auditLoading = true
    try {
      if (
        uploads.filter(
          (el) =>
            !el.files.type.includes('image') && !el.files.type.includes('pdf')
        ).length
      ) {
        this.openNotificationWithIcon(
          'error',
          'This file format is not available'
        )
      }
      const imgFiles = uploads.filter((el) => el.files.type.includes('image'))
      const pdfFiles = uploads.filter(
        (el) => el.files.type === 'application/pdf'
      )
      const readFileData = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = (e) => {
            resolve(e.target.result)
          }
          reader.onerror = (err) => {
            reject(err)
          }
          reader.readAsDataURL(file)
        })
      }
      if (pdfFiles.length) {
        const auditBody = {
          id: +this.currentAuditId,
          type: [...pdfFiles.map((el) => el.types)],
          comment: [...pdfFiles.map((el) => (el.comment ? el.comment : ''))],
        }
        const formData = new FormData()
        pdfFiles.forEach((file, i) => {
          formData.append(`uploads${i}`, file.files.originFileObj)
        })
        await this.saveGeneralFiles(auditBody, formData, 'pdf')
      }
      if (imgFiles.length) {
        const auditBody = {
          id: +this.currentAuditId,
          type: [...imgFiles.map((el) => el.types)],
          comment: [...imgFiles.map((el) => (el.comment ? el.comment : ''))],
        }
        const formData = new FormData()
        imgFiles.forEach((file, i) => {
          formData.append(`uploads${i}`, file.files.originFileObj)
        })
        await this.saveGeneralFiles(auditBody, formData)
      }
    } catch (error) {
      console.log(error, 'err')
    }
  }

  saveCheckListFiles = async (files) => {
    this.auditLoading = true
    const allFiles = Object.values(files).flat()
    const [uploads, invalidFiles] = allFiles.reduce(
      ([valid, invalid], el) => {
        if (
          +!el.id &&
          (el?.type.includes('image') ||
            el?.type.includes('text') ||
            el?.type.includes('video') ||
            this.allowedFileTypes?.includes(el?.type))
        ) {
          valid.push(el)
        } else if (+!el.id) {
          invalid.push(el)
        }
        return [valid, invalid]
      },
      [[], []]
    )
    if (invalidFiles.length) {
      invalidFiles.forEach((file) => {
        this.openNotificationWithIcon(
          'error',
          `This file format is not available: ${file.name}`
        )
      })
    }
    const auditBody = {
      audit_id: +this.currentAuditId,
      audit_tab_id: [...uploads.map((el) => el.audit_tab_id)],
      audit_check_list_id: [...uploads.map((el) => el.audit_check_list_id)],
    }
    const formData = new FormData()
    for (let i = 0; i < uploads.length; i++) {
      formData.append(`uploads${i}`, uploads[i].originFileObj)
    }
    await axios.post(
      `/api/node/v1/audits/upload/checkList/${auditBody.audit_id}`,
      formData,
      {
        params: auditBody,
      }
    )
  }

  saveMemoUpload = async (data) => {
    try {
      if (data.files) {
        const fileCreate = data.files?.filter((el) => !el.url)

        const lastFileName = data.files
          .map((el, i) => (!el.url ? { fileName: data.fileName[i] } : el))
          .filter((el) => !el.url)
        const arrFilenames = lastFileName.map((el) => el.fileName)
        const id = data.memo_id
        if (fileCreate.length) {
          const formData = new FormData()
          for (let i = 0; i < fileCreate.length; i++) {
            formData.append(
              `uploads${i}`,
              fileCreate[i].originFileObj,
              lastFileName[i].fileName
            )
            lastFileName.push(data.fileName[i])
          }
          const resp = await axios.post(
            `/api/node/v1/audits/upload/memo/memo/${id}`,
            formData,
            {
              params: {
                id: +id,
                fileName: arrFilenames,
              },
            }
          )
          if (resp) {
            return 'true'
          }
        } else {
          return 'false'
        }
      } else {
        return 'false'
      }
    } catch (error) {
      console.log(error, 'err')
    }
  }
  saveMemoUploadVideo = async (data) => {
    try {
      if (data.files) {
        const fileCreate = data.files

        const lastFileName = data.fileName

        const id = data.memo_id
        if (fileCreate) {
          const formData = new FormData()

          formData.append(`uploads`, fileCreate, lastFileName)

          const resp = await axios.post(
            `/api/node/v1/audits/upload/memo/memo/${id}`,
            formData,
            {
              params: {
                id: +id,
                fileName: [lastFileName],
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )

                const updatedFileMemo = {
                  ...data,
                  status: 'uploading',
                  percent: percentCompleted,
                  disabled: true,
                }
                this.uploadVideo = progressEvent.loaded

                // Обновляем данные в массиве
                const existingFileIndex = this.filesMemo.findIndex(
                  (item) => item.fileName === data.fileName
                )

                if (existingFileIndex !== -1) {
                  // Если элемент существует, обновляем его
                  this.filesMemo[existingFileIndex] = updatedFileMemo
                } else {
                  // Если элемент не существует, добавляем новый
                  this.filesMemo.push(updatedFileMemo)
                }

                this.filesMemo = [...this.filesMemo]
              },
            }
          )
          if (resp) {
            this.uploadVideo = null
            return 'true'
          }
        } else {
          return 'false'
        }
      } else {
        return 'false'
      }
    } catch (error) {
      const errorFileIndex = this.filesMemo.findIndex(
        (item) => item.fileName === data.fileName
      )
      if (errorFileIndex !== -1) {
        this.filesMemo[errorFileIndex] = {
          ...data,
          status: 'error',
          percent: 0,
          disabled: true,
        }
        this.filesMemo = [...this.filesMemo]
      }

      console.error('Ошибка при отправке видео файла:', error)
    }
  }

  downloadFile = async (file) => {
    try {
      const options = {
        responseType: 'blob',
        params: {
          id: +file.file_id,
        },
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          )
          const updatedFileMemo = {
            ...file,
            status: 'uploading',
            percent: percentCompleted,
            disabled: true,
          }
          this.loadOnefile = percentCompleted

          // Обновляем данные в массиве
          const existingFileIndex = this.filesMemo.findIndex(
            (item) => item.file_id === file.file_id
          )

          if (existingFileIndex !== -1) {
            // Если элемент существует, обновляем его
            this.filesMemo[existingFileIndex] = updatedFileMemo
          } else {
            // Если элемент не существует, добавляем новый
            this.filesMemo.push(updatedFileMemo)
          }

          this.filesMemo = [...this.filesMemo]
        },
      }
      const response = await axios.get(
        `/api/node/v1/audits/download/memo/${file.file_id}`,
        options
      )
      if (response.data) {
        this.loadOnefile = null
      }
      download(response.data, file.name)
    } catch (error) {
      const errorFileIndex = this.filesMemo.findIndex(
        (item) => item.file_id === file.file_id
      )
      if (errorFileIndex !== -1) {
        this.filesMemo[errorFileIndex] = {
          ...file,
          status: 'error',
          percent: 0,
          disabled: false,
        }
        this.filesMemo = [...this.filesMemo]
      }

      console.error('Ошибка при загрузке файла:', error)
    }
  }
  downloadAllFiles = async (files, nameFactory, nameMemo, type) => {
    try {
      const fileIds = files.map((file) => file.file_id)
      const fileName = `${nameFactory} ${nameMemo} ${type}`
      this.auditLoading = true
      this.statusLoad = ''

      const responseSizeArchive = await axios.get(
        '/api/node/v1/audits/fileMemoDownloadAll/TotalSize',
        {
          params: {
            files: fileIds,
          },
        }
      )

      this.loadDownload = responseSizeArchive.data.data
      axios.interceptors.request.use((config) => {
        if (config.url === '/api/node/v1/audits/fileMemoDownloadAll') {
          config.onDownloadProgress = (progressEvent) => {
            this.currentLoadDownload = progressEvent.loaded
            if (this.currentLoadDownload) {
              this.auditLoading = false
            }
            const percentCompleted = Math.round(
              (this.currentLoadDownload / this.loadDownload) * 100
            )
            this.progresLoad = percentCompleted
          }
        }
        return config
      })
      const response = await axios.get(
        '/api/node/v1/audits/fileMemoDownloadAll',
        {
          responseType: 'blob',
          params: {
            files: fileIds,
          },
        }
      )
      axios.interceptors.request.eject()
      if ((response.status = 200)) {
        this.progresLoad = null
        this.loadDownload = null
        this.currentLoadDownload = null
      }
      download(response.data, fileName)
    } catch (error) {
      this.statusLoad = 'exception'
      this.auditLoading = false
      this.openNotificationWithIcon('error', 'Failed to upload file')
      console.error('all filesMemo download error', error)
    }
  }
  closeErrorDownload = () => {
    this.currentLoadDownload = null
  }
  downloadOldAudit = async (id) => {
    try {
      if (id) {
        const index = this.downloadFileProgresOldAudit.findIndex(
          (file) => file.id === id
        )
        if (index !== -1) {
          this.downloadFileProgresOldAudit.splice(index, 1)
        }
        const fileObject = {
          id: id,
          progress: 0,
          status: false,
        }
        this.downloadFileProgresOldAudit.push(Object.assign({}, fileObject))

        const data = await axios.get('/api/node/v1/audits/download/oldAudit', {
          responseType: 'blob',
          params: {
            id,
          },
          onDownloadProgress: (progressEvent) => {
            const loaded = progressEvent.loaded
            const total = progressEvent.total
            const progressPercentage = Math.round((loaded / total) * 100)
            this.load = progressPercentage
            const fileIndex = this.downloadFileProgresOldAudit.findIndex(
              (el) => el.id === id
            )

            if (fileIndex !== -1) {
              this.downloadFileProgresOldAudit[fileIndex].progress =
                progressPercentage
              this.downloadFileProgresOldAudit[fileIndex].status = true
            }
            this.downloadFileProgresOldAudit = [
              ...this.downloadFileProgresOldAudit,
            ]
          },
        })

        const fileIndex = this.downloadFileProgresOldAudit.findIndex(
          (el) => el.id === id
        )
        if (fileIndex !== -1) {
          this.downloadFileProgresOldAudit[fileIndex].status = false
        }
        if (data) {
          this.downloadFileProgresOldAudit =
            this.downloadFileProgresOldAudit.filter((file) => file.id !== id)
        }
        const fileName = data.headers['content-disposition']
          .substr(data.headers['content-disposition'].lastIndexOf('=') + 2)
          .slice(0, -1)
        download(data.data, fileName)
      } else return
    } catch (error) {
      console.error(`Error downloading oldAudit`)

      const fileIndex = this.downloadFileProgresOldAudit.findIndex(
        (el) => el.id === id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgresOldAudit[fileIndex].status = 'exception'
      }

      this.downloadFileProgresOldAudit = [...this.downloadFileProgresOldAudit]
    }
  }
  saveAuditGeneralTab = async () => {
    switch (+this.activeAuditTab) {
      case 0:
        await this.saveAuditGeneral()
        break
      default:
    }
  }
  toTranslate = async (language, text) => {
    try {
      const response = await axios.post(
        `/api/node/v1/qcFeedback/toTranslate/`,
        {
          language,
          text,
        }
      )

      return response.data.data.translations
    } catch (error) {
      console.log(error, 'err')
    }
  }

  selectVisitorsAnalytics = (e) => {
    this.filtersAnalytics.visitorAnalytic = e
    this.loaderMainPage = true
  }
  selectCityAnalytics = (e) => {
    this.filtersAnalytics.city = e
  }
  selectSupplierAnalytics = (e) => {
    this.filtersAnalytics.supplier = e
  }
  selectScoreSupplierAnalytics = (e) => {
    this.filtersAnalytics.score = e
  }
  selectCityGeograhphyAnalytics = (e) => {
    this.filtersAnalytics.cityGeography = e
  }
  clearFiltersAnalytics = async () => {
    try {
      this.filtersAnalytics = {
        visits: null,
        role: [11, 3].includes(this.userTypeId)
          ? this.filtersAnalytics.role
          : null,
        date: [],
        typeVisits: null,
        visitorAnalytic: [],
        typeGeography: null,
        engineer: null,

        dateGeography: [],
        city: [],
        visibleTableGeography: null,
        supplier: [],
        score: [],
        dateSupplier: [],
        cityGeography: [],
      }
    } catch (error) {}
  }

  getUserAnalytics = async (visits, role, date, user) => {
    try {
      this.auditLoading = true

      let response = await axios.get(`/api/node/v1/audits/userAnalytics`, {
        params: {
          visits: visits,
          role: role,
          date: date,
          user: user,
        },
      })
      this.allUser = response.data.data.users
      this.usersSelectFilter = response.data.data.usersSelectFilter

      this.auditAnalytics = response.data.data.result

      this.auditLoading = false
    } catch (error) {}
  }
  popoversCloseAnaltyc = (visits, role, date) => {
    this.getUserAnalytics(
      visits,
      role,
      date,
      this.filtersAnalytics.visitorAnalytic.join()
    )
  }
  unSelectProductAnalytics = (visits, role, date) => {
    this.filtersAnalytics.visitorAnalytic = []
    this.getUserAnalytics(visits, role, date)
  }
  unSelectCityGeography = () => {
    this.filtersAnalytics.city = []
  }

  unSelectSupplierGeography = () => {
    this.filtersAnalytics.supplier = []
    this.filtersAnalytics.score = []
    this.getSupplierAnalyticsGeography()
  }
  unSelectCityGeograhphyAnalytics = () => {
    this.filtersAnalytics.cityGeography = []
  }
  getUserAnalyticsGeography = async (role, idRole, date) => {
    try {
      this.auditLoading = true

      let response = await axios.get(`/api/node/v1/audits/geographyAnalytics`, {
        params: {
          role: role,

          user: idRole,
          date: date,
        },
      })

      this.allEngineer = response.data.data.usersGeography

      this.EngineerGeography = response.data.data.updatedAudits

      this.auditLoading = false
    } catch (error) {}
  }
  getSupplierAnalyticsGeography = async () => {
    try {
      this.auditLoading = true

      let response = await axios.get(`/api/node/v1/audits/geographySupplier`, {
        params: {
          id: this.filtersAnalytics.supplier,
          score: this.filtersAnalytics.score,
        },
      })

      this.SupplierGeography = response.data.data.allAudits
      this.allNameAuditsInSelect = response.data.data.allNameAuditsInSelect

      this.auditLoading = false
    } catch (error) {}
  }
  getCityAnalyticsGeography = async () => {
    try {
      this.auditLoading = true

      let response = await axios.get(`/api/node/v1/audits/geographyCity`, {
        params: {},
      })

      this.CityGeography = response.data.data.cityAll

      this.auditLoading = false
    } catch (error) {}
  }
  approveAudit = async (approve) => {
    let approveStatus
    let action = false
    let implementation_date = false
    let accomplished = false
    const idLastReaudit = this.infoLastReaudit.id
    const idAudit = this.auditInfo.id

    if (approve) {
      const data = this.auditData['Audit Result']?.summary
      data.forEach((obj) => {
        if (obj.action === null || obj.action === '') {
          action = true
        }
        if (obj.implementation_date === null) {
          implementation_date = true
        }
        if (obj.accomplished === false) {
          accomplished = true
        }
      })
      if (!data.length) {
        approveStatus = 1
      }
      if (data.length && !action && !implementation_date && !accomplished) {
        approveStatus = 2
      }
      if (data.length && (action || implementation_date || accomplished)) {
        approveStatus = 3
      }
    } else {
      approveStatus = 0
    }

    let response = await axios.post(`/api/node/v1/audits/statusAudit`, {
      params: {
        idLastReaudit,
        idAudit,
        approveStatus,
        action,
        implementation_date,
        accomplished,
        approve: 'approve',
      },
    })
    await this.getAudit(idAudit)
    if (approveStatus === 0) {
      this.reaudit = false
    }
  }
  checkFileProgressOldAudit = (id, type) => {
    return this.downloadFileProgresOldAudit.find((file) => file.id === id)?.[
      type
    ]
  }
  disabledButtonDownloadOldAudit = (id) => {
    const oldAudit = this.downloadFileProgresOldAudit.find(
      (file) => file.id === id
    )
    return oldAudit ? oldAudit.status !== 'exception' : false
  }
  // предпросмотр файлов
  getFile = async (file, link) => {
    const fileId = file.file_id || file.id
    try {
      const fileObject = {
        fileId: fileId,
        type: file?.type,
        filename: file.original_name,
        progress: 0,
        status: false,
        data: [],
      }
      this.downloadFileProgres.push(Object.assign({}, fileObject))
      const data = await axios.get(`${link}${fileId}`, {
        responseType: 'blob',
        params: { id: fileId },
        onDownloadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded
          const total = progressEvent.total
          const progressPercentage = Math.round((loaded / total) * 100)
          this.load = progressPercentage
          const fileIndex = this.downloadFileProgres.findIndex(
            (el) => el.fileId === file.file_id
          )

          if (fileIndex !== -1) {
            this.downloadFileProgres[fileIndex].progress = progressPercentage
            this.downloadFileProgres[fileIndex].status = true
          }
          this.downloadFileProgres = [...this.downloadFileProgres]
        },
      })
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === fileId
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].data = data
        this.downloadFileProgres[fileIndex].status = false
      }
      return data
    } catch (error) {
      console.error(`Error downloading file`)
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.file_id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].status = 'exception'
      }
      this.downloadFileProgres = [...this.downloadFileProgres]
    }
  }
  //CHAT
  getCurrentTabNameChat = (tabId, chat) => {
    let tabKeys = {
      1: 'general',
      2: 'audit_result',
      3: 'warehouse',
      4: 'incoming_inspection',
      5: 'manufacturing',
      6: 'supplier_ability',
      7: 'supplier_facilities',
      8: 'memo',
    }
    return tabKeys[tabId]
  }
  getComments = async (tab) => {
    if (tab === 'general') {
      tab = 'chat_general'
    }
    let path = window.location.pathname.split('/')
    const auditID = path[path.length - 1]
    const response = await axios.get(
      `/api/node/v1/audits/chat/${tab}/${auditID}`,
      { params: { lang: this.commentsLanguage[tab] } }
    )
    if (response.data.success) {
      this.commentsCounts = response.data.data.counts
      this.commentsData[tab] = response.data.data.messages
      this.commentsUsers[tab] = response.data.data.users.map((el) => {
        return {
          value: el.name + ' ' + el.surname,
          label: el.name + ' ' + el.surname,
          id: el.id,
        }
      })
    }
  }
  postComment = async (text, tab, files, users) => {
    try {
      const formData = new FormData()
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append('chat_files', files[i])
        }
      }
      formData.append(`audit_id`, this.auditInfo.id)
      formData.append(`user_id`, this.userId)
      formData.append(`text`, text)
      formData.append(`users`, users)
      const response = await axios.post(
        `/api/node/v1/audits/chat/${tab}`,
        formData
      )
      if (response.data.success) {
        this.commentsCounts = response.data.data.counts
        this.commentsData[tab] = response.data.data.messages
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }
  deleteComment = async (commentId, tab) => {
    try {
      const response = await axios.delete(
        `/api/node/v1/audits/chat/${tab}/${commentId}`
      )
      if (response.data.success) {
        await this.getComments(tab)
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }
  downloadChatFile = async (file) => {
    const response = await axios.get(
      `/api/node/v1/audits/download/chatFiles/${file.id}`,
      {
        responseType: 'blob',
      }
    )
    if (response.status === 200) {
      download(response.data, file?.original_name)
    }
  }
  downloadFileCheckList = async (file) => {
    const data = await axios.get(
      `/api/node/v1/audits/download/checkListFile/${file.id}`,
      {
        responseType: 'blob',
      }
    )
    download(data.data, file.original_name)
  }
}

export default AuditStore
