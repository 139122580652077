import { makeAutoObservable, toJS } from 'mobx'
import api from '../utils/axiosAuthInterceptor'
import { notification } from 'antd'
import i18next from 'i18next'
import { debounce } from 'lodash'
const axios = api

class UpdatesStore {
  smart_sections = []
  updates = {
    data: [],
    user: {},
    isLoading: false,
    smart_section_id: null,
    sections_modules_id: null,
    searchValue: null,
  }
  templates = {
    data: [],
    open: false,
    deleteIndex: null,
    deletedPhotosArray: [],
  }
  createUpdate = {
    isOpen: false,
    tabs: ['w_global_update'],
    activeTabkey: 'w_global_update',
    isOpenConfirm: false,
    is_template: false,
    isNeedModules: false,
    isEditTemplate: false,
    isEditUpdate: false,
    indexHasModule: null,
    isNeedUpdate: false,
    updates: [
      {
        theme: '',
        positions: [
          { type: 'description', id: 1 },
          { type: 'dragger', id: 2 },
        ],
      },
    ],
    progressBar: {
      percent: 0,
      status: 'normal',
      text: null,
    },
    postingUpdates: false,
  }
  updateCard = {
    isOpen: false,
    updatesIndex: null,
    theme: '',
    isLoading: false,
    lang: null
  }

  constructor() {
    this.debouncedGetUpdates = debounce(this.getUpdates, 500)
    makeAutoObservable(this, {}, { deep: true })
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
    })
  }

  // UpdatesMain

  getUpdates = async () => {
    let updates = this.updates
    try {
      const params = {
        smart_section_id:this.updates.smart_section_id, 
        sections_modules_id: this.updates.sections_modules_id,
        searchValue: this.updates.searchValue,
      }
      updates.isLoading = true
      let response = await axios.get(`/api/node/v1/updates`, { params })
      let answer = response.data.data
      updates.data = answer.rows
      updates.user = answer.user
      console.log(answer.user)
      this.templates.data = answer.templates
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    } finally {
      updates.isLoading = false
    }
  }

  getSmartSections = async () => {
    try {
      let response = await axios.get(`/api/node/v1/updates/smartSections`)
      this.smart_sections = response.data.data
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    }
  }

  onChangeSeacrh = async (e) => {
    this.updates.searchValue = e.target.value
    console.log(e.target.value)
    if(Boolean(e.target.value)) {
      await this.debouncedGetUpdates()
    } else {
      await this.getUpdates()
    }
    
  }

  //Create Update
  createUpdateTriggerOpenModal = () => {
    this.createUpdate.isOpen = !this.createUpdate.isOpen
  }

  handleTabChange = (tab) => {
    this.createUpdate.activeTabkey = tab
  }

  addNewUpdate = () => {
    this.createUpdate.updates.push({
      theme: '',
      positions: [
        { type: 'description', id: 1 },
        { type: 'dragger', id: 2 },
      ],
    })
  }

  addFieldToForm = (updateIndex, fieldType) => {
    const newUpdates = [...this.createUpdate.updates]
    const currentPositions = newUpdates[updateIndex].positions
    const maxId = currentPositions.reduce(
      (max, pos) => (pos.id > max ? pos.id : max),
      0
    )

    const newField = { type: fieldType, id: maxId + 1 }
    currentPositions.push(newField)
    this.createUpdate.updates = newUpdates
  }

  onDragEnd(result, updateIndex) {
    const { source, destination } = result
    if (!destination) return

    const newUpdates = [...this.createUpdate.updates]
    const form = newUpdates[updateIndex]
    const [movedItem] = form.positions.splice(source.index, 1)
    form.positions.splice(destination.index, 0, movedItem)

    this.createUpdate.updates = newUpdates
  }

  beforeUploadPhotos(file, updateIndex, positionIndex) {
    const isImage = file.type.startsWith('image/')
    if (!isImage) {
      this.openNotificationWithIcon(
        'warning',
        `File: ${file.name} - is not image!`
      )
      return false
    }

    const newUpdates = [...this.createUpdate.updates]
    if (newUpdates[updateIndex].positions[positionIndex].type === 'dragger') {
      newUpdates[updateIndex].positions.splice(positionIndex, 1)
    }

    const maxId = newUpdates[updateIndex].positions.reduce(
      (max, pos) => (pos.id > max ? pos.id : max),
      0
    )

    const photoObj = { type: 'photo', id: maxId + 1, file: file }
    newUpdates[updateIndex].positions.push(photoObj)

    this.createUpdate.updates = newUpdates

    return false
  }

  onFinishGlobalUpdate = async (values, mainForm) => {
    try {
      this.createUpdate.postingUpdates = true
      const body = {
        smart_section_id: values.smart_section_id,
        sections_modules_ids: values?.sections_modules_ids
          ? values.sections_modules_ids
          : null,
        updates: this.createUpdate.updates,
        type: this.createUpdate.activeTabkey,
        is_template: this.createUpdate.is_template,
        isEditTemplate: this.createUpdate.isEditTemplate,
      }
      let postedUpdatesIds
      if (this.createUpdate.is_template && this.createUpdate.isEditTemplate) {
        postedUpdatesIds = await this.putUpdates(body)
      } else {
        postedUpdatesIds = await this.postUpdates(body)
      }

      const hasPhotoWithFile = this.createUpdate.updates.some((update) =>
        update.positions.some(
          (position) => position.type === 'photo' && position.file
        )
      )
      if (hasPhotoWithFile) {
        await this.postUpdatesImages(postedUpdatesIds)
      } else {
        this.openNotificationWithIcon('success', i18next.t('w_success'))
      }
      this.clearCreateUpdateState(mainForm)
      this.createUpdate.isNeedUpdate = true
    } catch (error) {
      console.error(error)
      this.createUpdate.postingUpdates = false
      this.createUpdate.progressBar.status = 'normal'
      this.createUpdate.progressBar.text = null
      this.createUpdate.progressBar.percent = 0
      this.createUpdate.is_template = false
    }
  }

  postUpdates = async (body) => {
    let responseFirst
    try {
      responseFirst = await axios.post(`/api/node/v1/updates`, body)
    } catch (error) {
      this.openNotificationWithIcon(
        'error',
        `${i18next.t('w_error')}: While trying save themes and descriptions!`
      )
      throw new Error()
    }
    return responseFirst.data.data
  }

  postUpdatesImages = async (postedUpdatesIds) => {
    const twoSecondsDelay = async () => {
      await new Promise((resolve) =>
        setTimeout(() => {
          this.createUpdate.progressBar.text = 'Returning in 2s'
          resolve()
        }, 1000)
      )
      await new Promise((resolve) =>
        setTimeout(() => {
          this.createUpdate.progressBar.text = 'Returning in 1s'
          resolve()
        }, 1000)
      )
      await new Promise((resolve) =>
        setTimeout(() => {
          this.createUpdate.progressBar.text = 'Returned!'
          this.createUpdate.progressBar.percent = 0
          resolve()
        }, 1000)
      )
    }
    const formData = new FormData()
    this.createUpdate.updates.forEach((update, updateIndex) => {
      update.positions.forEach((position, positionIndex) => {
        if (position.type === 'photo' && position.file) {
          formData.append(
            `updateImage`,
            position.file,
            `${postedUpdatesIds[updateIndex]}-${positionIndex}-${position.file.name}`
          )
        }
      })
    })
    try {
      this.createUpdate.progressBar.percent = 1
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve()
        }, 500)
      )
      await axios.post(`/api/node/v1/updates/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 89) / progressEvent.total
          )
          this.createUpdate.progressBar.percent = percentCompleted
          this.createUpdate.progressBar.text = `Uploading images`
        },
      })
      this.createUpdate.progressBar.percent = 100
      this.createUpdate.progressBar.status = 'success'
      this.createUpdate.progressBar.text = 'Upload complete!!!'
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve()
        }, 500)
      )
      await twoSecondsDelay()
    } catch (error) {
      this.createUpdate.progressBar.text = null
      this.createUpdate.progressBar.status = 'exception'
      await twoSecondsDelay()
      this.openNotificationWithIcon(
        'error',
        `${i18next.t(
          'w_error'
        )}: Cant upload images. Please try again or select other images! `
      )
      throw new Error()
    }
  }

  onCancelCreateUpdateModal = async (mainForm) => {
    if (this.createUpdate.postingUpdates) {
      this.openNotificationWithIcon(
        'error',
        `${i18next.t('w_error')}: Cant abort operation! Wait please!`
      )
      return
    }
    const values = mainForm.getFieldsValue()
    const hasValues = Object.values(values).some(Boolean)
    if (hasValues) {
      this.createUpdate.isOpenConfirm = true
      return
    } else {
      if (this.createUpdate.isNeedUpdate) {
        await Promise.all([this.getUpdates(), this.getSmartSections()])
      }
      this.clearCreateUpdateState(mainForm)
      this.createUpdate.isOpen = false
    }
  }
  onClickEditUpdate = async (index, mainForm) => {
      let update = this.updates.data[index]
      this.createUpdate.isEditTemplate =  true
      this.createUpdate.isOpen = true
      this.createUpdate.activeTabkey = update.type
      update.positions = update.update_positions
      this.createUpdate.updates = [update]
      console.log(update)
      mainForm.setFieldsValue({smart_section_id: update?.smart_section_id})
      if(update?.updates_sections_modules) {
        this.createUpdate.isNeedModules = true
        this.createUpdate.indexHasModule = this.smart_sections.findIndex(section => section.id == update?.smart_section_id)
        mainForm.setFieldsValue({sections_modules_ids: update?.updates_sections_modules[0].sections_modules_id})
      }
      await this.postUpdatesView(update.id)
    }

  clearCreateUpdateState = (mainForm) => {
    setTimeout(() => {
      mainForm.resetFields()
    }, 0)
    this.createUpdate = {
      ...this.createUpdate,
      tabs: ['w_global_update'],
      activeTabkey: 'w_global_update',
      isOpenConfirm: false,
      is_template: false,
      isNeedModules: false,
      isEditTemplate: false,
      isEditUpdate: false,
      indexHasModule: null,
      isNeedUpdate: false,
      updates: [
        {
          theme: '',
          positions: [
            { type: 'description', id: 1 },
            { type: 'dragger', id: 2 },
          ],
        },
      ],
      progressBar: {
        percent: 0,
        status: 'normal',
        text: null,
      },
      postingUpdates: false,
    }
  }

  onFinishFailedGlobalUpdate = (e) => {
    this.openNotificationWithIcon(
      'warning',
      i18next.t('w_please_fill_all_required_fields')
    )
  }

  deleteUpdateAttribute = (updateIndex, positionIndex) => {
    const updatedUpdates = [...this.createUpdate.updates]
    const link = updatedUpdates[updateIndex].positions[positionIndex].file_link
    console.log(link)
    if (link) {
      console.log(link)
      this.templates.deletedPhotosArray.push(link)
    }

    updatedUpdates[updateIndex] = {
      ...updatedUpdates[updateIndex],
      positions: updatedUpdates[updateIndex].positions.filter(
        (_, index) => index !== positionIndex
      ),
    }

    this.createUpdate.updates = updatedUpdates
  }

  deleteUpdate = (updateIndex) => {
    const updatedUpdates = [...this.createUpdate.updates]

    updatedUpdates.splice(updateIndex, 1)

    this.createUpdate.updates = updatedUpdates
  }

  onChangeTheme = (e, updateIndex) => {
    this.createUpdate.updates[updateIndex].theme = e.target.value
  }

  onChangeDescription = (content, updateIndex, positionIndex, mainForm) => {
    const isContentEmpty =
      !content || content.replace(/<[^>]*>/g, '').trim() === ''

    if (isContentEmpty) {
      this.createUpdate.updates[updateIndex].positions[positionIndex].value =
        null
      return
    }
    this.createUpdate.updates[updateIndex].positions[positionIndex].value =
      content
    mainForm.setFieldsValue({
      [`update=${updateIndex}_id=${this.createUpdate.updates[updateIndex].positions[positionIndex].id}`]:
        content,
    })
  }

  //UpdateCard
  openUpdateCard = async (index, section, needReplace = true) => {
    this.updateCard.updatesIndex = index
    this.updateCard.theme = section
    this.updateCard.isOpen = true
    if (needReplace) {
      const params = new URLSearchParams(window.location.search)
      params.set('update_id', this.updates.data[index].id)
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${params.toString()}`
      )
    }
    await this.postUpdatesView(this.updates.data[index].id)
  }
  onCancelUpdateCard = async () => {
    if(this.updateCard.lang) {
      await this.translateUpdateCard(this.updateCard.lang)
    }
    this.updateCard.isOpen = false
    const params = new URLSearchParams(window.location.search)
    params.delete('update_id')
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}?${params.toString()}`
    )
    await this.getUpdates()
    this.getSmartSections()
  }

  postUpdatesView = async (updates_id) => {
    try {
      let body = { updates_id }
      await axios.post(`/api/node/v1/updates/views`, body)
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    }
  }

  changeUpdateSlide = async (changer) => {
    if(this.updateCard.lang) {
      await this.translateUpdateCard(this.updateCard.lang)
    }
    changer === 'next' ?  this.updateCard.updatesIndex += 1 : 
    this.updateCard.updatesIndex -= 1
    const currentUpdate = this.updates.data[this.updateCard.updatesIndex]
    if (currentUpdate.updates_views.length === 0) {
      await this.postUpdatesView(currentUpdate.id)
    }
   
    const params = new URLSearchParams(window.location.search)
    params.set('update_id', currentUpdate.id)
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}?${params.toString()}`
    )
  }


  translateUpdateCard = async (lang) => {
    try{
      this.updateCard.isLoading = true
      const update = this.updates.data
      const updatesIndex = this.updateCard.updatesIndex
      if(this.updateCard.lang === lang) {
        if (update[updatesIndex]?.translatedTheme) {
          update[updatesIndex].translatedTheme = null;
        }
        update[updatesIndex].update_positions
          .filter(position => position.type === 'description')
          .forEach((position) => {
            if(position.translatedValue) {
              position.translatedValue = null; 
            }
          });
        this.updateCard.lang = null
        return
      }
      this.updateCard.lang = lang
    
     
      const text = [
        update[updatesIndex].theme, 
        ...update[updatesIndex].update_positions
          .filter(position => position.type === 'description')
          .map(position => position.value)
      ];
      const params = {
        lang,
        text
      }
      let response = await axios.get(`/api/node/v1/updates/card/translate`, { params })
      let translatedArray = response.data.data
      update[updatesIndex].translatedTheme = translatedArray.shift()
      update[updatesIndex].update_positions
      .filter(position => position.type === 'description')
      .forEach((position, index) => {
        position.translatedValue = translatedArray[index]; 
      });
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.updateCard.isLoading = false
    }
  }





  //Templates

  onEditClickTemplates = (template, mainForm) => {
    if (template.type === 'w_global_update') {
      let update = { ...this.createUpdate }
      update.isEditTemplate = true
      update.activeTabkey = 'w_global_update'
      update.updates = [
        {
          theme: template.theme,
          id: template.id,
          positions: template.update_positions,
        },
      ]
      const formValues = {
        smart_section_id: template.smart_section_id,
        theme_form_0: template.theme,
      }

      template.update_positions.forEach((position) => {
        if (position.type === 'description') {
          formValues[`update=0_id=${position.id}`] = position.value || null
        }
      })

      mainForm.setFieldsValue(formValues)

      if (template.updates_sections_modules.length > 0) {
        mainForm.setFieldsValue({
          sections_modules_ids: template.updates_sections_modules.map(
            (module) => module.sections_modules_id
          ),
        })
        update.isNeedModules = true
        update.indexHasModule = this.smart_sections.findIndex(
          (section) => section.id === template.smart_section_id
        )
      }
      update.isOpen = true
      this.createUpdate = { ...update }
    }
  }

  putUpdates = async (body) => {
    let responseFirst
    try {
      body.deletedPhotosArray = this.templates.deletedPhotosArray
      responseFirst = await axios.put(`/api/node/v1/updates`, body)
    } catch (error) {
      this.openNotificationWithIcon(
        'error',
        `${i18next.t('w_error')}: While trying update template!`
      )
      throw new Error()
    } finally {
      this.templates.deletedPhotosArray = []
    }

    return responseFirst.data.data
  }

  onOpenChangeTemplateDropDown = (open) => {
    this.templates.open = open
  }

  deleteTemplate = async (id, index) => {
    try {
      this.templates.deleteIndex = index
      await axios.delete(`/api/node/v1/updates/${id}`)
      this.openNotificationWithIcon('success', i18next.t('w_success'))
      this.templates.data.splice(index, 1)
    } catch (error) {
      this.openNotificationWithIcon(
        'error',
        `${i18next.t('w_error')}: While deleting template!`
      )
    } finally {
      this.templates.deleteIndex = null
    }
  }
}

export default UpdatesStore
